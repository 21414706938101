* {box-sizing: border-box;margin: 0;padding: 0;}
body {float: left;width: 100%;margin: 0;padding: 0;box-sizing: border-box;position: relative;overflow: hidden;}
.wrapper {float: left;width: 100%;overflow: hidden;}
/* html {scrollbar-color: #f1f1f1 #ccc;scrollbar-width: thin;}
html:hover {
scrollbar-color: #888 #555;
} */
.sidebarWrapper.close, .navLogo.close {
    width: 53px !important;
}
.panelwrapper.active.close {
    width: calc(100% - 53px);
}
.btn_close {
    padding: 0px 10px !important;
    font-size: 20px !important;
    outline: none !important;
    box-shadow: none !important;
    border: 0px !important;
}
#confimr_order_main {
    margin-top: 0px !important;
}
.adminmain {
float: left;
width: 100%;
background-color: #f2f2f2;
}
.profilUser h4 {
font-size: 15px;
margin: 0px;
}
.profilUser small {
text-transform: capitalize;
font-size: 13px;
}
.profilUser {
float: left;
width: auto;
line-height: 16px;
text-align: left;
}
.permissionmodel h5 {
font-size: 17px;
margin: 0px;
}
.permissiontableView tr td:first-child, .permissiontableView tr th:first-child {
text-align: left;
}
.permissiontableView td, .permissiontableView th {
text-align: center;
}
.permissionCenter {
float: left;
width: 100%;
text-align: center;
height: calc(100vh - 99px);
display: flex;
align-items: center;
justify-content: center;
}
.permissionCenter .errorMessageIcon {
font-size: 19px;
}
.permissiontableView tbody tr th.labeltd {
font-size: 13px;
font-weight: 400;
padding: 10px 10px;
background-color: #f6f6f6 !important;
color: #212529;
position: relative;
}
.searchInfo {
position: absolute;
top: 9px;
left: 7px;
background-color: #fff;
font-size: 13px;
cursor: pointer;
}

/* Targeting WebKit-based browsers */
.Bigform_custom_scroll::-webkit-scrollbar, .search_Navigations_wrapper .dropdown .dropdown-menu::-webkit-scrollbar {
width: 4px; /* Width of the scrollbar */
}

.Bigform_custom_scroll::-webkit-scrollbar-track, .search_Navigations_wrapper .dropdown .dropdown-menu::-webkit-scrollbar-track {
background-color: #f1f1f1; /* Track color */
}

.Bigform_custom_scroll::-webkit-scrollbar-thumb, .search_Navigations_wrapper .dropdown .dropdown-menu::-webkit-scrollbar-thumb {
background-color: #888; /* Thumb color */
border-radius: 6px; /* Rounded corners for the thumb */
}

.Bigform_custom_scroll::-webkit-scrollbar-thumb:hover, .search_Navigations_wrapper .dropdown .dropdown-menu::-webkit-scrollbar-thumb:hover {
background-color: #555; /* Thumb color on hover */
}
#viewmore_btn {
    background-color: #fff !important;
    padding: 5px 10px;
    font-size: 12px;
    color: #333 !important;
    border: 1px solid #333 !important;
    width: auto !important;
    float: right;
    margin-right: 10px;
    border-radius: 4px;
}

:root {
--loginColor: #0E0E30;
--maincolor: #0e39b6;
}

/* sidebar css */
.sidebarWrapper {float: left;width: 301px;display: flex;height: calc(100vh - 58px);border-right: 3px solid #fff;position: relative;}
.sidebarWrapper::after {content: "";float: left;width: 1px;height: 100%;background-color: #ddd;position: absolute;right: -3px;top: 0;}
.sidebarMain {float: left;width: 50px;background-color: #0E0E30;position: relative;}
.sidebarMain .flex-column {overflow: hidden;}
.sidebarMain .nav-link {
padding: 13px 10px;
float: left;
width: 100%;
text-align: center;
cursor: pointer;
position: relative;
display: flex;
align-items: center;
justify-content: center;
font-size: 15px;
color: #fff;
}
.sidebarMain .nav-link i {
line-height: normal;
}
.sidebarMain .nav-link img {
object-fit: contain;
object-position: center;
}
.sidebarMain .nav-link:hover, 
.sidebarMain .nav-link.active {
color: #10b068;
}

.sidebarMain .nav-link:hover::after,
.sidebarMain .nav-link.active::after {
content: "";
float: left;
width: 2px;
height: 100%;
background-color: #10b068;
position: absolute;
left: 0;
top: 0;
}
.sidebarMain .nav-link .active {
display: none;
}
.sidebarMain .nav-link:hover .inactive, 
.sidebarMain .nav-link.active .inactive {
display: none;
}
.sidebarMain .nav-link:hover .active,
.sidebarMain .nav-link.active .active  {
display: block;
}
.head_section {
float: left;
width: 100%;
background-color: #fff;
border-bottom: 1px solid #ddd;
height: 58px;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0px 15px 0px 0px;
}
.head_section a.nav-logo {
float: left;
width: auto;
}
.head_section a.nav-logo img {
width: 150px;
}
.head_section a.nav-logo.closed img {
    width: 40px;
}
.head_section .navLogo.close {
    padding-left: 6px;
}
.dropdownHeader {
float: right;
width: auto;
}
.dropdownHeader .dropdown i{
margin-right: 4px;
}
.dropdownHeader .dropdown .dropdown-toggle {
padding: 0px;
background: transparent !important;
color: #333 !important;
border: 0px;
border-radius: 0px;
line-height: normal;
box-shadow: none !important;
outline: none !important;
display: flex;
align-items: center;
column-gap: 8px;
}
.dropdownHeader .dropdown .dropdown-toggle .AvatarTitle {
    float: left;
    width: 30px;
    height: 30px;
    font-size: 14px;
    color: #fff;
    border-radius: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500; 
}
.sidebarWrapper .sidebar {
float: left;
width: calc(100% - 50px);
height: 100%;
background-color: #fff;
}
.sidebarWrapper .sidebar .flex-column .nav-link {
padding: 14px 12px;
color: #333;
font-size: 14px;
line-height: normal;
display: flex;
column-gap: 10px;
align-items: center;
justify-content: flex-start;
position: relative;
}
.sidebarWrapper .sidebar .flex-column .nav-link span {
float: left;
width: auto;
position: absolute;
right: 10px;
top: 15px;
font-size: 12px;
}
.sidebarWrapper .sidebar .flex-column .nav-link.active, 
.sidebarWrapper .sidebar .flex-column .nav-link:hover {
background-color: #10b068;
color: #fff;
}
.collapnseDrop  {
background-color: #eee;
border-bottom: 1px solid #eee;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul {
padding: 0px;
margin: 0px;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li {
float: left;
width: 100%;
font-size: 14px;
list-style: none;
}
.navLogo {
float: left;
border-right: 1px solid #ddd;
height: 100%;
width: 301px !important;
padding: 10px;
display: flex;
align-items: center;
justify-content: flex-start;
}
.pageTitle {
float: left;
width: auto;
text-transform: capitalize;
flex: 1;
font-size: 15px;
margin: 0px;
}
.Logout_btn {
float: left;
width: 100%;
font-size: 20px;
text-align: center;
position: absolute;
bottom: 10px;
left: 0;
right: 0;
cursor: pointer;
}
.Logout_btn i {
color: #dc3545 !important;
}
.dark_light_mode_btn {
    background-color: #fff;
    float: none;
    width: 70%;
    margin: 0 auto;
    border-radius: 16px 16px 16px 16px;
    padding: 6px 3px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 6px;
    position: absolute;
    bottom: 55px;
    left: 0;
    right: 0;
    cursor: pointer;
}
.dark_light_mode_btn .bi {
    float: none;
    width: 27px;
    height: 27px;
    text-align: center;
    display: flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
}
.dark_light_mode_btn .bi.active {
    background-color: #10b068;
    color: #fff;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
}
/* sidebar css */

/* login page css start here */
.loginWrapper {
float: left;
width: 100%;
position: relative;
height: 100vh;
background-image: url("../img/X3_Product_Banner.webp");
background-repeat: no-repeat;
background-position: center;
background-size: cover;
display: flex;
align-items: center;
justify-content: flex-start;
padding: 0px 8rem;
}
.login_main_wrapper {
float: left;
width: 410px;
height: auto;
background-color: #fff;
padding: 25px 40px 40px;
}
.logoheader {
color: #10b068;
font-weight: 700;
letter-spacing: 0.8px;
margin: 0 0 14px;
font-size: 39px;
text-align: left;
float: left;
width: 100%;
}
.logoheader img {
width: 200px;
}
.loginMainblog {
float: left;
width: 100%;
}
.loginMainblog h4 {
float: left;
width: 100%;
font-size: 20px;
margin: 0 0 15px;
}
.mainloginwrps {
float: left;
width: 100%;
height: 100%;
}
.formloginset .form-label {
float: left;
width: 100%;
font-size: 14px;
}
.mb-3 {
float: left;
width: 100%;
}
.formloginset input {
border-radius: 0px;
height: 38px;
font-size: 13px !important;
box-shadow: none !important;
}
.widthfullbtn {
width: 100%;
}
.formloginset select {
width: 100%;
border-radius: 0px;
height: 38px;
font-size: 13px;
padding: 10px 10px;
border-color: #ced4da;
outline: none;
box-shadow: none !important;
background-image: url("../img/caret-down.svg");
background-size: 10px;
background-repeat: no-repeat;
background-position: 96% center;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}
small.note {
font-size: 13px;
margin: 5px 0 0;
color: #7a7a7a;
}
.formloginset .btn {
background-color: #10b068 !important;
border-color: #10b068 !important;
border-radius: 0px;
padding: 8px 10px;
/* margin: 5px 0 0; */
width: 100%;
position: relative;
overflow: hidden;
z-index: 9;
}
body .btn.button-1{
background-color: #6c757d !important;
border-color: #6c757d !important;
border-radius: 0px;
padding: 8px 10px;
width: 100%;
position: relative;
overflow: hidden;
z-index: 9;
color: white!important;
}
body .btn.button-1.btn-sm {
font-size: 14px !important;
border-radius: 0px !important;
padding: 6.6px 10px !important;
}
body .btn.button-1:focus{
background-color: #7f878f!important;
}
.signInbtn::after {float: left;width: 100%;height: 100%;position: absolute;left: 0;top: 0;z-index: -1;content: "";background-color: #0E0E30;border-color: #0E0E30;transform: translateY(100%);-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;}
.signInbtn:hover::after {transform: translateY(0%);-webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;}
.passwordfiled {
float: left;
width: 100%;
position: relative;
}
#searchbtn {
margin: 0px;
}
.passwordfiled input {
padding-right: 35px;
}
.passwordfiled .showpassbtn {
width: 18px;
height: 18px;
position: absolute;
right: 9px;
top: 8px;
cursor: pointer;
}
.passwordfiled .showpassbtn svg {
float: left;
width: 100%;
}
.danger-colorset {
font-size: 12px;
color: #F84646;
float: left;
width: 100%;
margin: 4px 0 0;
}
/* login page css end here */

/* pannel css */
.panelwrapper {
float: left;
width: calc(100% - 301px);
height: calc(100vh - 58px);
padding: 10px;
position: relative;
}
.panelContentwrapper {
float: left;
width: 100%;
height: 100%;
background-color: #fff;
padding: 10px;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a {
float: left;
width: 100%;
text-decoration: none;
color: #333;
padding: 13px 15px 13px 35px;
font-size: 13px;
position: relative;
outline: none !important;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a:hover,
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a.active {
background-color: #ccc;
}
.sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a:after {
content: "o";
float: left;
font-weight: 500;
left: 14px;
position: absolute;
top: 12px;
width: auto;
}
#button-tooltip {
padding: 0px !important;
}
/* pannel css */

/* table filter */
.headerbtngroups {
float: left;
width: auto;
align-items: center;
display: flex;
}
.export_btncmn {
font-size: 14px;
padding: 6px 14px;
margin: 0 0 5px;
position: relative;
cursor: pointer;
display: flex;
align-items: center;
background-color: var(--loginColor) !important;
color: #fff !important;
margin-left: 10px;
}
.export_btncmn:hover {
background-color: #2769ff !important;
}
.graphs_columns {
float: left;
width: 100%;
position: relative;
margin: 5px 0 25px;
}
.productQuantyset {float: left;width: auto;display: flex;align-items: center;border: 1px solid #ddd;border-radius: 10px;overflow: hidden;background-color: #eee;max-width: 130px;margin: -5px 0 0;}
.cart_wrappperr li .cartbtn_wraps .quantity_product .productQuantyset {width: 100%;display: flex;justify-content: space-between;background: transparent;border: 0px;font-size: 18px;}
.productQuantyset .btn {padding: 5px 5px;outline: none !important;border: 0px !important;margin: 0px 5px;}
.productQuantyset .btn i {color: #6e6c6c;}
.productQuantyBox {float: left;width: auto;word-break: break-all;}
.redmednstar {color: red;font-size: 19px;line-height: 10px;display: inline-block;position: relative;top: 3px;}
.addQuantity i {color: var(--loginColor) !important;}
.graphs_columns h4 {float: left;width: 100%;font-size: 17px;margin: 0 0 20px;}
.piechartwraps {float: left;}
.card-header-New {float: left;margin: 0 0 15px;width: 100%;}
.card-header-New select {height: 36px !important;border-radius: 0px !important;font-size: 13px;}
.searchBoxwithbtn {float: left;position: relative;width: 260px;}
.searchBoxwithbtn .form-control {border: 1px solid #ddd;border-radius: 0;box-shadow: none!important;font-size: 13px;height: 36px;padding: 7px 35px 7px 27px;}
.searchBoxwithbtn.search .form-control{padding-right: 55px;}
.searchBoxwithbtn .form-control:not( ~ .searchbtn ){padding-right: 0px!important;}
.searchBoxwithbtn .searchbtn, .searchBoxwithbtn .searchbtn:focus {
position: absolute;
right: 0px;
top: 0px;
border-radius: 0px;
height: 100%;
width: 32px;
padding: 0px;
display: flex;
align-items: center;
justify-content: center;
background-color: #10b068;
border-color: #10b068;
box-shadow: none !important;
}
.searchBoxwithbtn .searchbtn:focus-visible  {
background-color: #10b068 !important;
border-color: #10b068 !important;
}
.searchBoxwithbtn .searchbtn:hover{
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
}
.searchBoxwithbtn .searchbtn:focus-visible {
background-color: #10b068 !important;
border-color: #10b068 !important;
}
.searchBoxwithbtn .icon-tabler-search {
width: 19px;
}
.serachBoxWIth .searchbtn, .serachBoxWIth .searchbtn:focus {
position: absolute;
right: 0px;
top: 0px;
border-radius: 0px;
height: 100%;
width: 32px;
padding: 0px;
display: flex;
align-items: center;
justify-content: center;
background-color: #10b068;
border-color: #10b068;
margin: 0 0 0 !important;
}
.serachBoxWIth .searchbtn:hover {
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
}
.serachBoxWIth .icon-tabler-search {
width: 19px;
}
.card-header-right {float: right;width: auto;display: flex;}
.activestatusselet, .userLimitselect {background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);background-position: 91%;background-repeat: no-repeat;background-size: 13px;border: 1px solid #ddd!important;border-radius: 0;cursor: pointer;float: left;font-size: 14px;height: 36px;width: 90px !important;}
.selectuserteamDrop {background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);background-position: 91%;background-repeat: no-repeat;background-size: 13px;border: 1px solid #ddd!important;border-radius: 0;cursor: pointer;float: left;font-size: 14px;height: 36px;width: 200px !important;}
.card-header-right select:first-child {margin-right: 10px;}
.card-header-right select:nth-child(2) {margin-right: 10px;}
.card-header-right select:nth-last-child(2) {margin-right: 10px;}
.card-header-right select {box-shadow: none!important;}
.head_cmn_btn {font-size: 14px !important;border-radius: 0px !important;padding: 6.5px 10px !important;background-color: #0E0E30 !important;color: #fff !important;border-color: var(--loginColor) !important;}
.head_cmn_btn:hover {background-color: #333 !important;}
.head_reset_btn, .head_reset_btn:focus, .head_reset_btn:active {font-size: 14px;border-radius: 0px;margin: 0 0 0 10px;padding: 6.6px 10px;background-color: red !important;color: #fff !important;border-color: red !important;}
.head_reset_btn:hover {background-color: red !important;}
.cmn_export_btn, .cmn_export_btn:focus, .cmn_export_btn:active {font-size: 14px;border-radius: 0px;margin: 0 0 0 10px;padding: 6.6px 10px;background-color: #00b13a !important;color: #fff !important;border-color: #00b13a !important;}
.cmn_export_btn:hover {background-color: #008d21 !important;}
.cmn_export_btn img {width: 18px;line-height: normal;margin: 0px 3px 0px 0px;}
.head_cmn_btn i {margin: 0 4px 0 0px;}
.head_reset_btn i {margin: 0 4px 0 0px;}
.searchBoxwithbtn .searchclear {
float: right;
width: auto;
position: absolute;
top: 9px;
right: 40px;
font-size: 13px;
cursor: pointer;
color: red;
background-color: #fff;
}
/* table filter */

/* table css  */
.tableView {float: left;width: 100%;position: relative;}
.tableView thead tr th {font-size: 13px;font-weight: 400;padding: 10px 10px;background-color: #f6f6f6;color: #212529;position: relative;}
.tableView thead tr th.active {
color: #10b068;
font-weight: 500;
}
.tableView thead tr th.inactive {
color: red;
font-weight: 500;
}
.gstreporttableadded thead tr th, .gstreporttableadded tbody tr td {
white-space: nowrap;
}
/* .orderlistTable thead tr th, .orderlistTable tbody tr td {
white-space: nowrap;
} */
.Main_sectionGSTReports .card-header-New {
width: auto;
position: absolute;
right: 0;
top: 0;
}
.tableView tbody {border-top: 0px !important;}
.tableView tbody tr td {font-size: 13px;vertical-align: middle;color: #212529;}
.AvtarMain {float: left;width: auto;border-radius: 0.375rem;border: 1px solid #eee;overflow: hidden;}
.AvtarMain img {width: 60px;}
.table-hover>tbody>tr:hover>* {background-color: transparent !important;--bs-table-accent-bg: none !important;--bs-table-bg-state: transparent !important;}
.badge {
font-size: 12px !important;
font-weight: 500 !important;
letter-spacing: 0.5px !important;
padding: 8px 10px !important;
}
.badge.bg-success {
background-color: #effdf1 !important;
color: #2aa13d !important;
}
.badge.bg-danger {
background-color: #fbebeb !important;
color: #dc3545 !important;
}
.badge.bg-primary {
background-color: #eaf3ff !important;
color: #0d6efd !important;
}
.edittable  {
border-radius: 0px !important;
padding: 0px !important;
line-height: normal !important;
font-size: 17px !important;
box-shadow: none !important;
background-color: transparent !important;
color: #000 !important;
border: 0px !important;
outline: none !important;
}
.edittable i {
line-height: normal;
}
.exportbtn {
background-color: #10b068 !important;
border-color: #10b068 !important;
color: #fff !important;
font-size: 14px !important;
border-radius: 0px !important;
padding: 6.6px 10px !important;
margin-right: 10px;
}
.exportbtnMain {
margin-right: 10px;
font-size: 19px !important;
padding: 2px 7px !important;
margin: 0 7px 0 0px !important;
line-height: normal;
background-color: #10b068 !important;
border-color: #10b068 !important;
color: #fff !important;
border-radius: 0px !important;
width: auto !important;
}
.exportbtn i {
margin: 0 4px 0 0px;
}
.skipbtn {
background-color: #212529 !important;
border-color: #212529 !important;
color: #fff !important;
font-size: 14px !important;
border-radius: 0px !important;
padding: 6.6px 10px !important;
margin-right: 10px;
}
#UploadCmnBTN .spinner-border {
    height: 16px;
    width: 16px;
    position: absolute;
    right: 9px;
    top: 9px;
}
.addSalerperformBtn {
    background-color: #10b068 !important;
    border: 0px !important;
    color: #fff !important;
    padding: 8.5px 15px !important;
    border-radius: 0px !important;
    font-size: 14px !important;
}
.addSalerperformBtn.salespersionClearbtn {
    background-color: #212529 !important;
}
.inputBoxs.monthly_target_fields {
    border-radius: 0px;
    font-size: 13px;
    height: 38px;
    box-shadow: none !important;
}
.salesFranchiseErrorModal .modal-dialog {
    max-width: 650px;
}
/* table css  */

/* common toggle css */
.comnToggle {float: left;width: auto;}
.comnToggle i {font-size: 25px;cursor: pointer;line-height: normal;}
.comnToggle .bi-toggle2-on {color: #008fff;}
/* common toggle css */

/* form css */
.tabsWrappers {
float: none !important;
width: 100%;
max-width: 75%;
margin: 15px auto 0;
display: table;
padding: 0px 60px;
position: relative;
border-top: 0px;
border-bottom: 0px;
}
.formloginset .form-label {
float: left;
width: 100%;
font-size: 14px;
}
.mb-3 {
float: left;
width: 100%;
}
.formloginset textarea {
border-radius: 0px;
height: 120px;
font-size: 13px;
box-shadow: none !important;
resize: none;
}
.formloginset .form-control[type=file] {
line-height: 29px;
}
.widthfullbtn {
width: 100%;
}
.formloginset select {
width: 100%;
border-radius: 0px;
height: 38px;
font-size: 13px;
padding: 6px 10px;
border-color: #ced4da;
outline: none;
box-shadow: none !important;
background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);
background-size: 10px;
background-repeat: no-repeat;
background-position: 96% center;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}
.tablecollapsewraps {
background-color: transparent !important;
}
.tablecollapsewraps table th {
background-color: #f8f8f8;
font-size: 12px;
padding: 10px 12px;
font-weight: 500;
}
.tablecollapsewraps table td {
font-size: 12px;
}
.formData {
float: left;
width: 100%;
font-size: 14px;
}
.formsearchList {
float: left;
width: 100%;
border-bottom: 1px solid #eee;
margin-bottom: 12px;
}
.formbtn {
background-color: #10b068 !important;
border-color: #10b068 !important; 
border-radius: 0px !important;
padding: 8px 25px !important;
margin: 5px 0 0 !important;
position: relative;
overflow: hidden;
z-index: 9;
}
.ToggleMainWrap {
display: flex;
float: left;
width: 100%;
align-items: center;
justify-content: flex-start;
column-gap: 10px;
}
.formloginset {
float: left;
width: 100%;
position: relative;
}
.formloginset .HeaderDivider:nth-last-child(2) {
margin: 19px 0 18px;
}
.HeaderDivider {
float: left;
width: 100%;
padding: 30px 15px 10px 15px;
border: 1px solid #eee;
margin: 19px 0 30px;
position: relative;
}
.HeaderDivider h6.subtitleForm {
float: left;
width: auto;
position: absolute;
background-color: #f9fafb;
padding: 10px 15px;
font-size: 14px;
top: -18px;
left: 15px;
margin: 0px;
border: 1px solid #eee;
}
.btnGroupswraps {
float: left;
width: 100%;
display: flex;
column-gap: 10px;
margin-bottom: 10px;
}
.btnGroupswraps .Resetbtn {
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
border-radius: 0px !important;
padding: 8px 10px !important;
margin: 5px 0 0 !important;
position: relative;
overflow: hidden;
z-index: 9; 
width: auto !important;
}
#GetDetailsbntn {
margin-top: 0px !important;
}
.filtr_Form {
float: left;
width: 100%;
margin-bottom: 15px;
}
.datepickField {
float: left;
width: 100%;
position: relative;
}
.datepickField input {
border-radius: 0px;
height: 38px;
width: 100%;
font-size: 13px;
box-shadow: none !important;
border: 1px solid #dee2e6 !important;
padding: .375rem .75rem;
outline: none !important;
background-image: url("../img/calendar.png");
background-repeat: no-repeat;
background-size: 16px;
background-position: 90% center;
}
.datepickField.min input {
background-position: 96% center;
}
.Addbtn {
background-color: #10b068 !important;
border-color: #10b068 !important;
border-radius: 0px !important;
padding: 8px 10px !important;
margin: 5px 0 0 !important;
position: relative;
overflow: hidden;
z-index: 9;
width: auto !important;
}
.react-datepicker-wrapper {
width: 100%;
}
.card-header-New .formloginset input {
height: 36px !important;
}
.totaltd th {
background-color: #f6f6f6 !important;
color: #212529 !important;
font-size: 13px;
font-weight: 500;
padding: 10px 10px !important;
}

.selectMultiselect .godown-select__input-container.css-qbdosj-Input,
.selectMultiselect .godown-select__input-container {
margin: 0px;
padding-bottom: 0px;
padding-top: 0px;
}
.selectMultiselect .godown-select__value-container.css-1fdsijx-ValueContainer {
padding: 0px 8px !important;
}
.selectMultiselect .godown-select__control.css-13cymwt-control, .selectMultiselect .css-t3ipsp-control {
border-color: #dee2e6 !important;
border-radius: 0px;
outline: none !important;
box-shadow: none !important;
}
.selectMultiselect .css-13cymwt-control, .selectMultiselect .css-1jqq78o-placeholder, .selectMultiselect .css-1dimb5e-singleValue {
font-size: 13px !important;
}
.formloginset #selectMultiselectBrand input{
height: 28px !important;
}

.formloginset .whloginhbtn{
width:100%;
padding:5px !important;
margin:0px !important
}

.formloginset .getDetailButton{
width:100px;
padding: 6px 7px!important;
margin:0px !important
}
/* form css */

/* image upload  */
.uploadfielsbuttons .uploadfilebuttons {margin-bottom: 17px;}
.imageuploads {float: left;width: 100px;height: 100px;border-radius: 20px;position: relative;border: 1px solid #ddd;}
.imageuploads img {float: left;width: 100%;height: 100%;border-radius: 20px;object-fit: cover;object-position: center;}
.imageuploads .closeIcon {float: left;width: 17px;height: 17px;position: absolute;right: -2px;top: -2px;cursor: pointer;background-color: #fff;border-radius: 100%;}
.imageuploads .closeIcon::after {content: "";float: left;height: 100%;width: 100%;-webkit-mask-image: url("../img/closebutton.png"); mask-image: url("../img/closebutton.png"); mask-position: center; -webkit-mask-position: center; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-size: contain; -webkit-mask-size: contain; background-color: red !important;}

.pdffileviewo .closeIcon {float: left;width: 17px;height: 17px;position: absolute;right: -7px;top: -3px;cursor: pointer;background-color: #fff;border-radius: 100%;}
.pdffileviewo .closeIcon::after {content: "";float: left;height: 100%;width: 100%;-webkit-mask-image: url("../img/closebutton.png"); mask-image: url("../img/closebutton.png"); mask-position: center; -webkit-mask-position: center; mask-repeat: no-repeat; -webkit-mask-repeat: no-repeat; mask-size: contain; -webkit-mask-size: contain; background-color: red !important;}

.fileloader {float: left;width: 28px;height: 28px;position: absolute;right: -6px;background-color: #fff;border-radius: 100%;top: -5px;padding: 5px;box-shadow: 0px 0px 17px -3px #ccc;}
.fileloader img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;}
.fileloader.active {padding: 0px;}
.uploadbuttons {float: left;width: 100px;height: 100px;position: relative;cursor: pointer;border: 1px solid #eee;border-radius: 20px;}
.uploadbuttons .uploadfilebox img {float: left;height: 100%;width: 100%; border-radius: 20px;}
.uploadfilebox img {float: left;height: 100%;width: 100%; border-radius: 20px;object-fit: cover;object-position: top;}
.uploadbuttons input.uploadfilInput {position: absolute;visibility: hidden;height: 100%;width: 100%;left: 0;top: 0;}
.uploadbuttons .fileloader label {float: left;width: 100%;height: 100%;cursor: pointer;}
.uploadbuttons .fileloader label img {float: left;width: 100%;height: 100%;object-fit: contain;object-position: center;}
.error_medotory {float: left;width: 100%;font-size: 13px;color: red;}
.uploadfilebox {float: left;width: 100%;height: 100%;}
/* image upload  */

/* side content css */
.MainHeader {
float: left;
width: 100%;
margin-bottom: 15px;
padding-bottom: 7px;
text-align: right;
border-bottom: 1px solid #ddd;
}
.dashboard_Main_section .MainHeader {
overflow: auto;
white-space: nowrap;
}
.dashboard_Main_section .MainHeader_Tab li:hover::after, .dashboard_Main_section .MainHeader_Tab li.active::after {
bottom: -7px;
}

.MainHeader .backbtn {
padding: 0px 10px;
font-size: 13px;
background: transparent !important;
box-shadow: none !important;
text-decoration: none;
color: #333;
}
.FranchiseWallet .backbtn {
padding: 0px 10px;
font-size: 13px;
background: transparent !important;
box-shadow: none !important;
text-decoration: none;
color: #333;
}
.selectMultiselect {
float: left;
width: 100%;
position: relative;
}
.searchWrapper {
min-height: 12px !important;
padding: 3px 10px !important;
border-radius: 0px !important;
border: 1px solid #ddd !important;
}
.mendatory_star {
color: red;
}
.multiSelectContainer {
background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAAsQAAALEBxi1JjQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACOSURBVEiJ7c/LCoFRFIDR5WEkKUlJiZRiwMCzmngjiQi5ZcSAM5HLfxtI/1d7cNq12oe8vJ+v8PQeY5jSnGLyblnEDNeEs0D52wUlzBPgS1SifrOKVQx8g3pUPFTDOgK+RSMuHmpi9wE/oJMUD7Wwf4Ef0U2Lh9ru1wb8hF5WeKiPMy4YZY2HBo/Jy/unbpb0TLQqPZ0IAAAAAElFTkSuQmCC);
background-position: 95%;
background-repeat: no-repeat;
background-size: 13px;
}
.multiSelectContainer input {
height: 33px !important;
margin-top: 0px !important;
}
.selectMultiselect.removeBackground .multiSelectContainer {
background-image: none !important;
}
.chip {
padding: 3px 9px !important;
}
.optionListContainer {
z-index: 99 !important;
}
.MainHeader_Tab {
float: left;
width: auto;
padding: 0px;
margin: 0px;
display: flex;
align-items: center;
column-gap: 15px;
position: relative;
}
.MainHeader_Tab li {
list-style: none;
font-size: 15px;
cursor: pointer;
font-weight: 500;
position: relative;
}
.MainHeader_Tab li:hover, .MainHeader_Tab li.active {
color: #10b068;
}
.MainHeader_Tab li:hover::after, .MainHeader_Tab li.active::after {
content: "";
float: left;
width: 100%;
height: 3px;
background-color: #10b068;
position: absolute;
left: 0;
bottom: -8px;
}
/* side content css */

.signInbtn .spinner-border {
height: 21px;
width: 21px;
border-width: 3px;
}
.commonModal .notformsection {
text-align: center;
line-height: 28px;
font-size: 17px;
}
.commonModal .notformsection .logoutIconbtn {
font-size: 25px;
color: red;
}
.commonModal .modal-dialog .modal-content {
border-radius: 0px !important;
}
.commonModal .modal-dialog .modal-footer .btn-secondary {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
}
.commonModal .modal-dialog .modal-footer .addbtncmn  {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
background-color: #10b068 !important;
border-color: #10b068 !important;
}
.commonModal .modal-dialog .modal-content .modal-header .btn-close {
box-shadow: none !important;
outline: none !important;
}
.modal-header .btn-close {
box-shadow: none !important;
}
.addTableModal .modal-dialog{
max-width: 780px;
}
.StockInwardLogDetailsTable .modal-dialog{
max-width: 900px;
}
.OrderDetailsModal .notformsection {
text-align: center;
line-height: 28px;
font-size: 17px;
}
.downloadaction {
color: #333;
font-size: 18px;
float: right;
}
.productbilladdform {
float: left;
width: 100%;
text-align: left;
padding: 13px 20px;
border: 1px solid #eee;
border-radius: 10px;
margin: 10px 0 0;
}
.btn_add {
padding: 6px 10px;
border: 1px solid #ddd;
border-radius: 0px;
outline: none !important;
}
.images_labels_wrapper {
float: left;
width: 100%;
}
.images_labels_wrapper li {
float: left;
width: 100%;
position: relative;
margin-bottom: 5px;
}
.images_labels_wrapper li .bi-trash3-fill {
float: right;
color: red;
cursor: pointer;
}
.StockDetailsReportPage .card-header-New .card-header-right .exportbtn {
display: none;
}
.OrderDetailsModal .notformsection .logoutIconbtn {
font-size: 25px;
color: red;
}
.OrderDetailsModal .modal-dialog .modal-content {
border-radius: 0px !important;
}
.OrderDetailsModal .modal-dialog .modal-footer .btn-secondary {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
}
#approvlaMargin {
margin-top: 0px !important;
}
.isdemo_flags {
font-size: 11px !important;
font-weight: 500 !important;
letter-spacing: 0.5px !important;
padding: 6px 8px !important;
border-radius: 4px;
}
#columnfullwith {
width: 100% !important;
}
.OrderDetailsModal input {
border-radius: 0px;
box-shadow: none !important;
}
.OrderDetailsModal .modal-dialog .modal-footer .addbtncmn  {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
background-color: #10b068 !important;
}
.OrderDetailsModal .modal-dialog .modal-content .modal-header .btn-close {
box-shadow: none !important;
outline: none !important;
}
.OrderDetailsModal .modal-dialog {
max-width: 65%;
}

.errorDetailsModal .notformsection {
text-align: center;
line-height: 28px;
font-size: 17px;
}
.errorDetailsModal .notformsection .logoutIconbtn {
font-size: 25px;
color: red;
}
.errorDetailsModal .modal-dialog .modal-content {
border-radius: 0px !important;
}
.errorDetailsModal .modal-dialog .modal-footer .btn-secondary {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
}
.errorDetailsModal .modal-dialog .modal-footer .addbtncmn  {
border-radius: 0px !important;
font-size: 15px;
padding: 8px 18px;
background-color: #10b068 !important;
}
.errorDetailsModal .modal-dialog .modal-content .modal-header .btn-close {
box-shadow: none !important;
outline: none !important;
}
#GetDetailsbntn.filterBlackbtn{
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
border-radius: 0px !important;
padding: 7.5px 10px !important;
margin: 0px 0 0 10px !important;
position: relative;
overflow: hidden;
z-index: 9;
width: auto !important;
}
/* pagination css start here */
.paginationCustom {float: left;width: 100%;padding: 0px;margin: 9px 0px 0px;}
.paginationCustom li:first-child button, .paginationCustom li:last-child button,
.paginationCustom li:nth-child(2) button, .paginationCustom li:nth-last-child(2) button {
color: transparent;
position: relative;
}
.paginationCustom li:first-child button::before {
content: "\F22D";
font-family: 'bootstrap-icons';
color: #333;
opacity: 1;
font-size: 17px;
position: absolute;
left: 6px;
line-height: normal;
}
.paginationCustom li:nth-child(2) button::before {
content: "\F129";
font-family: 'bootstrap-icons';
color: #333;
opacity: 1;
font-size: 20px;
position: absolute;
left: 5px;
line-height: normal;
}
.paginationCustom li:last-child button::before {
content: "\F133";
font-family: 'bootstrap-icons';
color: #333;
opacity: 1;
font-size: 21px;
position: absolute;
left: 0;
line-height: normal;
}
.paginationCustom li:nth-last-child(2) button::before {
content: "\F133";
font-family: 'bootstrap-icons';
color: #333;
opacity: 1;
font-size: 20px;
position: absolute;
right: 5px;
line-height: normal;
}
.paginationCustom li:last-child button::before {
content: "\F231";
font-family: 'bootstrap-icons';
color: #333;
opacity: 1;
font-size: 17px;
position: absolute;
right: 0;
line-height: normal;
}
.paginationCustom li .rpb-item--active {background-color: #10b068 !important; color: #fff !important;}
.paginationCustom li button {width: 29px;height: 29px;min-width: 29px;font-size: 14px;border-radius: 100%;}
/* pagination css end here */

.godown-select__menu {z-index: 9999 !important;}
.Bigform_custom_scroll {
float: left;
width: 100%;
overflow-x: hidden;
overflow-y: auto;
padding-right: 5px;
}
.selectMultiselectCategory .godown-select__input {
height: 39px !important;
}
.godown-select-container .selectMultiselectBrand .godown-select__input {
height: 40px !important;
}

#selectMultiselectBrand .css-16xfy0z-control {
border-radius: 0px !important;
}
.card-header-New.card-header-salesPerson .card-header-right #selectMultiselectBrand {
    width: 240px;
    margin-right: 10px;
}
.card-header-New.card-header-salesPerson .card-header-right #selectMultiselectBrand input, .card-header-New.card-header-salesPerson .card-header-right #selectMultiselectBrand .godown-select__control{
height: 36px !important;
min-height: 36px !important;
}
.card-header-New.card-header-salesPerson .card-header-right #selectMultiselectBrand .godown-select__value-container {
    padding: 0px 8px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}

.tableView tbody .btn-link {
padding: 0px;
font-size: 13px;
background: transparent !important;
}
.tableView tbody .btn-link-color {
color: #0d6efd !important;
cursor: pointer;
font-weight: 400;
}
#activestatusselet {
width: 100% !important;
}
#FranchiseUserReportWidth {
width: 130px !important;
}
.commonModal.addBoxModalNew .modal-dialog {
max-width: 680px;
}
#FranchiseUserReportWidthNew {
width: 230px !important;
}
.newsumbitbnt  {
background-color: #10b068 !important;
color: #fff !important;
font-size: 14px !important;
border-radius: 0px !important;
padding: 6.6px 10px !important;
margin-right: 10px;
}
.table-description {
height: 90px;
width: 180px !important;
border-radius: 0px !important;
box-shadow: none !important;
}
.cancelBtn {
background-color: #10b068 !important;
color: #fff !important;
font-size: 14px !important;
border-radius: 0px !important;
padding: 6.6px 10px !important;
}
.tableBolanchor {
color: #00f;
cursor: pointer;
}
.ScrollbarsSidebar .view {
margin-right: -17px !important;
padding-right: 20px;
margin-bottom: 0px !important;
}
.sotklevelbtn {
float: right;
position: relative;
right: 20px;
}
.serachBoxWIth {
float: left;
width: 100%;
position: relative;
}
.serachBoxWIth .spinner-border {
position: absolute;
right: 40px;
top: 9px;
height: 20px;
width: 20px;
border-width: 3px;
}
.defaultvalueField {
float: left;
width: 100%;
border: 1px solid #ddd;
height: 40px;
display: flex;
align-items: center;
padding: 10px;
font-size: 13px;
cursor: no-drop;
background-color: #eee;
}
.defaultvalueFieldaddress {
float: left;
width: 100%;
border: 1px solid #ddd;
padding: 10px;
font-size: 13px;
cursor: no-drop;
background-color: #eee;
}
.defaultvalueFieldaddress ul {
padding-left: 15px;
margin-bottom: 0px;
}
.defaultvalueFieldaddress ul li {
margin-bottom: 3px;
font-size: 13px;
}
.defaultvalueFieldaddress ul li:last-child {
margin-bottom: 0px;
}
.pincode_error {
float: left;
width: auto;
position: absolute;
right: 42px;
top: 6px;
cursor: pointer;
}
.filedinfomsg {
font-size: 12px;
float: right;
margin-right: 10px;
cursor: pointer;
}
.UploadCmnBTN {
position: relative;
}
.UploadCmnBTN input[type="file"] {
position: absolute;
left: 0;
top: 0;
visibility: hidden;
}
.leftFormheader {
float: left;
width: inherit;
}
#UploadCmnBTN {
background-color: #0e0e30!important;
border-color: #0e0e30!important;
border-color: var(--loginColor)!important;
border-radius: 0!important;
color: #fff!important;
font-size: 14px!important;
padding: 6.6px 10px!important;
position: relative;
}
.UploadLoader {
float: left;
width: auto;
margin: 5px 0px 0px 10px;
}
.UploadLoader .spinner-border {
height: 23px;
width: 23px;
border-width: 4px;
}
.uplaodedFilename {
float: left;
width: auto;
padding: 7px 20px 7px 34px;
font-size: 14px;
word-break: break-word;
background-color: #eee;
border-radius: 30px;
border: 1px solid #ddd;
display: flex;
align-items: center;
position: relative;
}
.exsting_newdetails {
float: left;
width: 100%;
margin: 15px 0 0;
}
.exsting_newdetails h4 {font-size: 15px;margin: 0 0 12px;}
.uplaodedFilename i:first-child {
font-size: 20px;
position: absolute;
left: 8px;
}
.uplaodedFilename .bi-x-circle-fill {
color: red;
position: absolute;
right: -5px;
top: 8px;
z-index: 99;
background-color: #fff;
line-height: normal;
border-radius: 100%;
font-size: 18px;
cursor: pointer;
height: 19px;
width: 19px;
}
.uplaodedFilename .bi-x-circle-fill::before {
vertical-align: baseline;
}
.ScrollbarsSidebarHide .formloginset {
padding: 0px 15px 0px 0px;
}
.btn.disabled {
cursor: no-drop !important;
}
.actionbtn {
float: left;
width: 17px;
height: 17px;
padding: 0px !important;
border: 0px !important;
outline: none !important;
box-shadow: none !important;
background: transparent !important;
-webkit-transition: 0.2s;
-moz-transition: 0.2s;
-ms-transition: 0.2s;
-o-transition: 0.2s;
transition: 0.2s;
}
.actionbtn img {
float: left;
height: 100%;
width: 100%;
}
.actionbtn.active img {
transform: rotate(180deg);
-webkit-transition: 0.2s;
-moz-transition: 0.2s;
-ms-transition: 0.2s;
-o-transition: 0.2s;
transition: 0.2s;
}
.panelContentwrapper .table tbody .actionbtn {
margin-right: 12px;
}
.panelContentwrapper .table tbody .actionbtn:last-child {
margin-right: 0px;
}
.table-bordered>:not(caption)>* {
border-color: #dee2e6 !important;
}
.tablecollapsewraps .table {
margin-bottom: 0px !important;
}
.datepickField .react-datepicker__close-icon {
right: 38px;
}
.datepickField .react-datepicker__close-icon::after {
background-color: red;
border-radius: 100% !important;
height: 13px !important;
width: 13px !important;
padding: 1px 1px !important;
font-size: 12px !important;
line-height: 0.5 !important;
}
.react-datepicker .react-datepicker__month-select {
height: auto !important;
padding: 1px 5px;
border-radius: 8px;
width: 100px;
}
.react-datepicker .react-datepicker__year-select {
height: auto !important;
padding: 1px 7px;
border-radius: 8px;
width: 70px;
}
#mb-3 {
margin-bottom: 7px !important;
}
.filtercollapsebtn {
background-color: #0E0E30;
color: #fff;
font-size: 14px;
padding: 6px 9px;
}
#stocklevelExportbtn {
width: auto;
margin: 0px 7px 0px;
padding: 7.5px 10px !important;
}
#PurchaseOrderbtn {
width: auto;
margin: 0px;
padding:7.5px 25px !important;
}
#PurchaseOrderClearbtn {
width: auto;
margin: 0px;
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
padding:7.5px 15px !important;
}
#PurchaseOrderbtnnew {
width: auto;
margin: 0px;
padding: 7.5px 11px !important;
}
#PurchaseOrderClearbtnnew {
width: auto;
margin: 0px;
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
padding: 7.5px 9px !important;
}
#PurchaseOrderClearbtnnew i {
margin: 0px !important;
}
#userLimitselect {
width: 85px !important;
}
.notformsection ul {
text-align: left;
margin: 0px;
padding: 10px 10px;
background-color: #eee;
border-radius: 10px;
}
#print_btn_function {
padding: 0px !important;
height: 21px;
}
.notformsection ul li {
font-size: 16px;
padding-bottom: 9px;
display: flex;
align-items: center;
justify-content: center;
column-gap: 9px;
}
.notformsection ul li:last-child {
padding-bottom: 0px;
}
.notformsection ul li span {
float: left;
width: 24px;
height: 24px;
background-color: #333;
color: #fff;
display: flex;
align-items: center;
text-align: center;
justify-content: center;
border-radius: 100%;
font-size: 14px;
}
.QauntityManage {
float: left;
width: auto;
display: flex;
align-items: center;
column-gap: 4px;
background-color: #eee;
border-radius: 7px;
padding: 2px 5px;
}
.QauntityManage .btn {
padding: 4px 5px;
outline: none !important;
box-shadow: none !important;
border: 0px !important;
font-size: 15px;
}
.QauntityManage h2 {
font-size: 15px;
line-height: normal;
margin: 0px;
}
.QauntityManage .remove .bi {color: red;}
.QauntityManage .add .bi {color: #10b068;}
.GSTAmounttotal {
float: left;
width: 100%;
padding-left: 17px !important;
margin: 0px;
padding: 8px 0px;
border-top: 1px solid #ddd;
border-bottom: 1px solid #ddd;
margin-bottom: 10px;
}
.GSTAmounttotal li {
float: left;
width: 100%;
padding: 2px 0px;
font-size: 14px;
color: #333;
}
.GSTAmounttotal li label {
font-weight: 500;
float: left;
}
.FranchiseWallet {
float: left;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 0px;
border-bottom: 1px solid #ddd;
margin-bottom: 12px;
font-size: 15px;
}
.FranchiseWallet .Franchise_name {
padding: 2px 0px;
font-size: 14px;
color: #333;
display: flex;
column-gap: 10px;
}
.FranchiseWallet .Franchise_name label {font-weight: 500;}
.FranchiseWallet .FranchiseBlance_name {
font-size: 14px;
color: #333;
display: flex;
column-gap: 10px;
background-color: #f5f5f5;
padding: 6px 15px;
border-radius: 20px;
}
.FranchiseWallet .FranchiseBlance_name label {font-weight: 500;}
.GSTAmounttotal textarea.form-control {
border-radius: 0px;
box-shadow: none !important;
width: 300px;
float: left;
font-size: 13px;
padding: 8px 10px;
height: 95px;
}
.PurchaseOrderCheckbox .form-check {
display: flex;
align-items: center;
column-gap: 8px;
}
.PurchaseOrderCheckbox .form-check-input[type=checkbox] {
height: 17px;
width: 17px;
}
.Purchase_Order_Mendetory .godown-select__placeholder::after {
content: "*";
color: red;
}
.pdffileviewo {
float: left;
width: 100%;
padding: 6px 23px 6px 14px;
font-size: 14px;
border: 1px solid #ddd;
background-color: #f9f9f9;
border-radius: 7px;
position: relative;
display: flex;
column-gap: 8px;
word-break: break-word;
}
.smallLabelData {
float: left;
width: 100%;
padding: 8px 10px;
border: 1px solid #ddd;
font-size: 13px;
position: relative;
height: 38px;
cursor: no-drop;
}
.smallLabelData small {
float: left;
width: auto;
background-color: #fff;
position: absolute;
left: 3px;
top: -8px;
padding: 0px 8px;
font-size: 11px !important;
}
.smallLabelData div {
display: inline-block;
width: 100%;
white-space: nowrap;
overflow: hidden !important;
text-overflow: ellipsis;
}
.react-datepicker-popper {
z-index: 9999999;
}
#backbtbackgt {
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
}
.smallLabelData .clearbtn {
position: absolute;
right: -6px;
top: -7px;
line-height: normal;
background-color: #fff;
border-radius: 100%;
cursor: pointer;
}
.smallLabelData .clearbtn i {
color: red;
}
#FranchiseUserReport .userLimitselect {
margin-right: 10px;
}
.notinternetFoundPage {
float: left;
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
background: rgba(255,255,255,0.5);
backdrop-filter: blur(4px);
display: flex;
align-items: center;
justify-content: center;
z-index: 9999;
}
.notinternetFoundPage .connectwrapp p {
margin-bottom: 25px;
}
.notinternetFoundPage .connectwrapp {
float: left;
width: 540px;
padding: 35px;
background-color: #fff;
border: 1px solid #ddd;
}

.notinternetFoundPage .connectwrapp h2 {
font-weight: 600;
margin: 0 0 12px;
}
.notinternetFoundPage .connectwrapp .btn {
background-color: #10b068 !important;
border-radius: 0px;
border-color: #10b068 !important;
padding: 9px 20px;
font-size: 16px;
}
.notinternetFoundPage .connectwrapp .bi-wifi-off {
font-size: 40px;
color: red;
}
.formloginsetStaticData .form-label {
margin: 0 0 5px !important;
}
.formloginsetStaticData .mb-3 {
margin-bottom: 10px !important;
}
.qntyClass {
border-radius: 0px;
box-shadow: none;
width: 110px !important;
}
.account-table{border-spacing: 2px;    border-collapse: collapse;print-color-adjust: exact;font-size: 13px;    color: #495057;margin: 0px!important;}
.account-table> tr>td, .account-table> tr>th {border: 1px solid #000000;border-right: 1px solid #000000;}
.account-table> tr:last-child>td, .account-table> tr:last-child>th{border-bottom: 1px solid #000000;}
.account-table> tr:first-child>td, .account-table> tr:first-child>th{border-top: 1px solid #000000;}
.account-table> tr>td, .account-table tr>td, .account-table tr>th{padding: 5px;}
.account-table h5{font-size: 14px;}
.account-table h2{font-size: 18px;}
.account-table hr{ margin: 0 -1px;padding: 0px!important;}
.account-table h5,
.account-table h2,
.account-table p{margin: 0px;padding: 0px!important;}
.account-table .bt-0{border-top-width: 0px!important;}
.account-table .bl-0{border-left-width: 0px!important;}
.account-table .bb-0{border-bottom-width: 0px!important;}
.account-table .br-0{border-right-width: 0px!important;}
.account-table .logo .text-center{width: calc(100% - 360px);}
.account-table .tax-invoice{
background: #10b068;
color: #fff;
padding: 10px 26px;
font-size: 20px;
font-weight: bold;
border-radius: 18px;
display: inline-block;
}
.t-body-nowrap .carrier.min{min-width: 140px;}
.t-body-nowrap .carrier{white-space: nowrap;min-width: 170px;}
.table-common tr>th{font-size: 13px!important;}
.table-common tr>td{font-size: 13px!important;}
span.light-green{
background-color: #eaf7ec !important;
color: #2aa13d;
font-weight: 500;
}
span.light-orange{
background-color: #ffc107 !important;
color: white;
font-weight: 500;
}
span.light-red{
background-color: #dc3545 !important;
color: white;
font-weight: 500;
}
span.light-grey{
background-color: #acadb1 !important;
color: white;
font-weight: 500;
}
.btn.btn-icon{
height: 35px;
width: 35px;
align-items: center;
justify-content: center;
display: inline-flex;
outline: 0px!important;
border-color: transparent!important;
box-shadow: none!important;
}
.btn.btn-icon::after{display: none;}
.btn.btn-icon i{font-size: 19px;}
.modal-invoice .btn.btn-icon{position: absolute;right: 50px;}
.invoice_model{
-webkit-print-color-adjust: exact;
print-color-adjust: exact;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
-webkit-color-adjust: exact;
color-adjust: exact;
}
.invoice-details-common img{object-fit: contain;}
.createshortBox {
padding: 0px !important;
font-size: 27px !important;
line-height: normal !important;
outline: none !important;
border: 0px !important;
}
.addBoxbtns {
background-color: #6c757d !important;
border-color: #6c757d !important;
color: #fff !important;
padding: 6px 15px !important;
font-size: 15px !important;
border-radius: 0px !important;
}
.deleteItemBtn {
color: red;
font-size: 15px;
cursor: pointer;
}
#addBoxbtns {
margin: 0px;
width: auto;
}
.selectBoxAnchor {
float: right;
font-size: 14px;
text-decoration: underline;
color: blue;
cursor: pointer;
}
.createshortBox i {
line-height: normal;
color: #10b068;
}
@media print {
.account-table{border-spacing: 2px;    border-collapse: collapse;print-color-adjust: exact;font-size: 13px;    color: #495057;margin: 0px!important;}
.account-table tr>td, .account-table tr>th {border: 1px solid #000000!important;}
}

.commonLoaderwarap {
float: left;
width: 100%;
height: 100%;
position: fixed;
left: 0;
top: 0;
display: flex;
align-items: center;
justify-content: center;
background-color: rgba(0,0,0,0.6);
z-index: 99999;
color: #fff;
column-gap: 10px;
}
.commonLoaderwaraplogin {
float: left;
width: 100%;
height: 100%;
position: fixed;
left: 0;
top: 0;
display: flex;
align-items: center;
justify-content: center;
background-color: rgba(0,0,0,0.6);
z-index: 999;
color: #fff;
column-gap: 10px;
}
.serachBoxWIthNewMain .filedinfomsg {
position: absolute;
left: 10px;
top: 11px;
}
.serachBoxWIthNewMain input {
padding-left: 32px;
}
.replacebtn {
color: #fff !important;
padding: 6.4px 10px !important;
margin: 0px !important;
}
.border-right {
position: relative;
}
.border-right::after {
content: "";
float: left;
width: 1px;
height: 57%;
background-color: #ccc;
position: absolute;
right: 0;
top: 0;
}
.errorDetailsModal .modal-body p {
float: left;
width: 100%;
word-break: break-word;
}
.customerdetails {
float: left;
width: 100%;
font-size: 14px;
margin: 0 0 10px;
}
#submitframebtn {
width: auto;
padding: 6.9px 10px !important;
margin: 0px !important;
}
.customerdetails label {
float: left;
width: auto;
margin-right: 7px;
font-weight: 500;
}
ul.fileuploadListy {
float: left;
width: 100%;
margin: 0px;
padding: 0px;
margin-top: 10px;
}
ul.fileuploadListy li {
float: left;
width: 100%;
list-style: none;
}
.fileuploadListy .uplaodedFilename {
margin-bottom: 10px;
}
.fileuploadListytable {
float: left;
width: 100%;
padding-left: 15px;
margin: 0px;
}
.fileuploadListytable li {
float: left;
width: 100%;
margin-bottom: 5px;
}
.UploadCmnBTN.UploadCmnAttchmentBTN .spinner-border {
height: 20px;
width: 20px;
position: absolute;
right: 10px;
top: 6px;
}
.vertical {
border-left: 2px solid #10b068;
height: 50px;
margin-left: 9px;
}
.completed{
display: flex;
align-items: center;
background-color: #10b068;
border-radius: 50%;
width: 20px;
height: 20px;
justify-content: center;
} 

.add-icon-francise-brand{
margin-top: 29px;
}

.add-icon-francise-brand .AddItemBtn {
color: white;
font-size: 25px;
cursor: pointer;
}

.permissionmodel .modal-dialog{
max-width: 1200px;
}

.child {
font-size: 14px;
}
.formloginset .ToggleMainWrap .permission-btn, .permissionbtn{
background-color: #080606 !important;
border-radius: 30px !important;
padding: 7px 15px !important;
font-size: 13px !important;
color: #fff !important;
border-color: #080606 !important;
}
.Rm-Quick-Login-disable {
cursor: no-drop;
}
.permissiontablAdminTolls #productMastersRMSMWH.form-check-input:disabled,
.permissiontableView #productMastersAfs.form-check-input:disabled ,
#after_sales_and_service_menu #productMasters.form-check-input:disabled {
background: gray;
cursor: no-drop;
}
.selectec_type_wraps {
    float: left;
    width: 100%;
    margin: 0 0 9px;
    font-size: 14px;
}
.selectec_type_wraps label {
    float: left;
    width: auto;
    margin-right: 8px;
    font-weight: 500;
}
.usertyperPermission {
width: auto;
float: right;
margin-left: 10px;
text-transform: capitalize;
font-size: 15px;
padding: 8px 20px;
line-height: normal;
background-color: #46ff46;
border-radius: 18px;
}
#margintopRemove {
margin-top: 0px !important;
}

.iframe_wrapper {
float: left;
width: 100%;
height: calc(100vh - 145px);
position: relative;
overflow: auto;
}
.iframe_wrapper iframe {
margin: 0;
padding: 0;
display: block;
overflow: hidden;
}
.errorMessageIcon {
color: red;
}
.kvyregisterdnoregs {
float: right;
font-size: 12px;
margin-left: 20px;
border-left: 1px solid #dddd;
padding-left: 12px;
}
.kvyregisterdnoregs .form-check.form-switch {
margin: 0px;
min-height: auto;
display: flex;
align-items: center;
column-gap: 8px;
}
.kvyregisterdnoregs .form-check.form-switch input {height: 14px;}
.anchorDownoad {
color: #0d6efd;
text-decoration: underline;
cursor: pointer;
}
#btn-groups-main-id.btn-groups-main {
display: flex;
float: left;
width: 100%;
justify-content: space-between;
align-items: center;
row-gap: 11px;
padding: 25px 1rem 20px !important;
flex-direction: column;
}
#btn-groups-main-id.btn-groups-main .btn {
width: 100%;
border: 2px solid #0E0E30;
color:#0E0E30;
position: relative;
float: left;
overflow: hidden;
z-index: 1;
-webkit-transition: 0.5s;
-moz-transition: 0.5s;
-ms-transition: 0.5s;
-o-transition: 0.5s;
transition: 0.5s;
}
#btn-groups-main-id.btn-groups-main .btn::after {
content: "";
background-color: #10b068;
float: left;
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
z-index: -9;
transform: translateY(100%);
-webkit-transition: 0.5s;
-moz-transition: 0.5s;
-ms-transition: 0.5s;
-o-transition: 0.5s;
transition: 0.5s;
}
#btn-groups-main-id.btn-groups-main .btn:hover {
border: 2px solid #10b068;
color: #fff !important;
-webkit-transition: 0.5s;
-moz-transition: 0.5s;
-ms-transition: 0.5s;
-o-transition: 0.5s;
transition: 0.5s;
}
#btn-groups-main-id.btn-groups-main .btn:hover::after {
transform: translateY(0%);
-webkit-transition: 0.5s;
-moz-transition: 0.5s;
-ms-transition: 0.5s;
-o-transition: 0.5s;
transition: 0.5s;
}
.commonModalStockInward .modal-dialog {
max-width: 360px;
}
#btn-groups-main-id.btn-groups-main .btn .bi-bicycle {
font-size: 19px;
line-height: normal;
}
#Order-NotBilled-Row td, #Order-NotBilled-Row td:hover {
background-color: #fff1d7 !important;
background: #fff1d7 !important;
}
#Order-NotBilled-Row td .not_billed_orange, #Order-NotBilled-Row td:hover .not_billed_orange {
background: #ffdd9d !important;
color: #ae7100 !important;
}
#Order-NoActive-Row td .badge.bg-danger, #Order-NoActive-Row td:hover .badge.bg-danger {
background-color: #f6d2d2 !important;
color: #dc3545 !important;
}
#Order-NoActive-Row td, #Order-NoActive-Row td:hover {
background-color: #fbebeb !important;
background: #fbebeb !important;
}
.status_mark.yet_to_start {
    background-color: #cdebff !important;
    color: #005085 !important;
}
.status_mark.expired {
    color: #00AC20 !important;
    background-color: #00AC20 #d8f3dd !important;
}
.status_mark.expired {
    background-color: #f1f1f1 !important;
    color: #8D8D8D !important;
}
.status_mark.in_progress {
    color: #4d3900;
    background-color: #ffe188 !important;
}
.status_mark.is_closed {
    color: #222222;
    background-color: #dadada !important;
}
#exportbtn {
padding: 7.4px 10px !important;
}
.FranchiseOrdersTablesDetails .modal-dialog{
max-width: 800px;
}
.Bike_Condition_iamges .AvtarMain {
margin-right: 9px;
cursor: pointer;
}
.FranchiseOrdersTablesDetailsTableERP .modal-dialog{
max-width: 900px;
}
.FranchiseOrdersTablesDetailsTableGeneralERP .modal-dialog{
max-width: 1000px;
}
.Main_section_warehouse .exportbtn {
margin-left: 10px;
}
.settting_Staus_wrapper {
float: left;
width: 100%;
position: relative;
}
.settting_Staus input {
border-radius: 0px;
height: 40px;
box-shadow: none !important;
font-size: 13px;
}
.settting_Staus_wrapper .settting_Staus {
float: left;
width: 170px;
display: flex;
align-items: center;
margin-right: 25px;
}
.settting_Staus.settting_Staus_score input {
text-align: center;
padding: 10px 12px 10px 12px !important;
}
.settting_Staus.settting_Staus_score .badge {
width: 100px !important;
}
.settting_Staus_wrapper .settting_Staus .badge {
float: left;
width: 70px;
height: 40px;
border-radius: 0px;
display: flex;
align-items: center;
justify-content: center;
font-size: 13px !important;
}
.commonDeleteModals p {
font-size: 17px;
}
.erperro_mesage {
float: left;
width: 40%;
padding-left: 15px;
margin: 10px 0 0;
}
.erperro_mesage li {
float: left;
width: 100%;
word-wrap: break-word;
white-space: normal;
margin-bottom: 3px;
}
.is_dispached.badge {
background-color: #ffcfe0 !important;
color: #4d3900;
}
.settting_Staus_wrapper .settting_Staus .statusPercentafe {
float: left;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
position: relative;
}

.settting_Staus_wrapper .settting_Staus .statusPercentafe input {
height: 40px;
border-radius: 0px;
box-shadow: none !important;
font-size: 13px;
padding: 10px 40px 10px 9px;
}
.settting_Staus_wrapper .settting_Staus .statusPercentafe span {
float: right;
width: auto;
position: absolute;
right: 0;
height: 100%;
padding: 10px 10px;
display: flex;
align-items: center;
line-height: normal;
}
.settting_Staus_wrapper .settting_Staus.success .statusPercentafe input {
border-color: #eaf7ec !important;
}
.settting_Staus_wrapper .settting_Staus.success .statusPercentafe span {
background-color: #eaf7ec;
color: #2aa13d !important;
}
.settting_Staus_wrapper .settting_Staus.danger .statusPercentafe input {
border-color: #fbebeb  !important;
}
.settting_Staus_wrapper .settting_Staus.danger .statusPercentafe span {
background-color: #fbebeb ;
color: #dc3545 !important;
}
.save_percentage {
border-radius: 0px !important;
background-color: #10b068 !important;
color: #fff !important;
border-color: #10b068 !important;
padding: 8px 13px !important;
font-size: 15px !important;
}
.point_sections_headers {
float: left;
width: 100%;
margin-bottom: 14px;
text-align: right;
}
.Bike_Condition_iamges {
float: left;
width: 100%;
}
.Bike_Condition_iamges ul {
margin: 0px;
padding-left: 15px;
}
.Bike_Condition_files div {
float: left;
width: auto;
cursor: pointer;
}
.Bike_Condition_iamges ul li {
float: left;
width: 100%;
position: relative;
}
.Bike_Condition_iamges ul li .youtube_wrapper {
float: left;
width: auto;
position: relative;
cursor: pointer;
}
/* preview image modal css */
.Image_preview_wrapper {
float: left;
width: 100%;
height: calc(100dvh);
position: fixed;
margin: 0 auto;
text-align: center;
padding: 13px 25px 25px 25px;
z-index: 9999;
overflow: auto;
left: 0;
right: 0;
top: 0;
color: #fff;
}
.preivew_image {
float: left;
width: 100%;
height: 100%;
}
.preivew_image img {
height: 100%;
}
.preivew_image video {
height: 100%;
}
.Image_preview_wrapper h4 {
float: left;
width: 100%;
margin: 0 0 20px;
}
.Image_preview_wrapper .bi-download {
float: right;
cursor: pointer;
color: #fff;
font-size: 34px;
position: absolute;
right: 80px;
top: 2px;
}
.Image_preview_wrapper .bi-x-lg {
float: right;
cursor: pointer;
color: #fff;
font-size: 38px;
position: absolute;
right: 20px;
top: 0px;
}
.background_wrapper {
float: left;
width: 100%;
height: 100%;
position: fixed;
left: 0;
top: 0;
z-index: 99;
background: rgba(0, 0, 0, 0.5);
}
.Image_preview_wrapper_header {
float: left;
width: 100%;
text-align: center;
position: relative;
}
.Bike_Condition_iamges .AvtarMain img {
height: 60px;
}
.edittable.edittableretry {
font-size: 15px !important;
display: flex;
column-gap: 7px;
}
.edittable.edittableretry:hover {
color: #10b068 !important;
text-decoration: underline;
}
.MainHeader_Tab .sendemailstocks {
float: right;
width: auto;
position: absolute;
right: 0;
top: -5px;
font-size: 14px;
padding: 6px 10px;
line-height: normal;
background-color: #0E0E30 !important;
border-color: #0E0E30 !important;
color: #fff !important;
border-radius: 0px;
}
.commonModalStockInwardEmails .form-control {
border-radius: 0px !important;
box-shadow: none !important;
outline: none !important;
border-color: #dee2e6 !important;
font-size: 13px;
height: 38px;
}
.commonModalStockInwardEmails .images_labels_wrapper {
padding: 0px;
margin: 0px;
}
.commonModalStockInwardEmails .images_labels_wrapper li {
list-style: none;
background-color: #eee;
padding: 7px 30px 7px 13px;
font-size: 14px;
border-radius: 30px;
width: auto;
}
.commonModalStockInwardEmails .images_labels_wrapper li i {
position: absolute;
right: 9px;
top: 7px;
color: red;
cursor: pointer;
}
.type_checkbox_wrapper {
float: left;
width: 100%;
display: flex;
align-items: center;
}
.type_checkbox_wrapper .form-label {
display: flex;
align-items: center;
flex: auto;
direction: rtl;
justify-content: flex-end;
column-gap: 7px;
}
.commonModalStockInwardEmails .permissionbtn .spinner-border {
height: 15px;
width: 15px;
}
.error_medotory .bi-x-circle-fill {
font-size: 11px;
}
.add_images_wraper .btn_add {
    position: absolute;
    top: 0;
    right: 0;
}
.images_labels_wrapper_listss li {    
    float: left;
    width: 100%;
    margin-bottom: 10px;
    font-size: 15px;
    word-break: break-all;
    padding-right: 15px;
}
.images_labels_wrapper_listss li strong {
    float: left;
    width: 100%;
    font-size: 15px;
}
.images_labels_wrapper_listss li .bi-trash3-fill {
    position: absolute;
    right: 0;
    top: 0;
}
.images_labels_wrapper_listss {
    padding-left: 20px;
}
.add_images_wraper {
    float: left;
    width: 100%;
    position: relative;
}
.rangerefrence_wrapper {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}
.uploade_images_wrapper {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    margin: 0 0 10px;
}
.uploade_images_wrapper .image_preview_wraps {
    float: left;
    width: 100%;
    border: 1px solid #ddd;
    height: 90px;
    border-radius: 10px;
    position: relative;
}
.downloadbtn#downloadbtn {
    font-size: 19px;
    padding: 2px 7px;
    margin: 0 7px 0 0px;
    line-height: normal;
    background-color: #333 !important;
    border-color: #333 !important;
    color: #fff !important;
    border-radius: 0px;
    width: auto !important;
}
.order_warning_error {
    float: left;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #fff;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 17px;
    flex-direction: column;
    row-gap: 10px;
}
.order_warning_error .bi-ban {
    font-size: 40px;
    color: red;
}
.erp_outstanding_main_section .date_time_stamp {
    right: 200px;
}
.uploade_images_wrapper .image_preview_wraps img {
    float: left;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.uploade_images_wrapper .wrapps_upload_img .image_preview_wraps .bi-trash3-fill {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 99;
    font-size: 13px;
    color: red;
    cursor: pointer;
}
.uploade_images_wrapper_list {
    padding: 0px !important;
}
.uploade_images_wrapper_list li {
    float: left;
    width: auto;
    list-style: none;
    margin-right: 10px;
}
.uploade_images_wrapper_list li .wrapps_upload_img {
    float: left;
    width: 90px;
    text-align: center;
}
.uploade_images_wrapper_list li .wrapps_upload_img img {
    width: 100%;
    height: 74px;
    object-fit: cover;
    object-position: center;
}
.uploade_images_wrapper_list li .wrapps_upload_img .image_preview_wraps {
    border-radius: 0.375rem;
    border: 1px solid #eee;
    overflow: hidden;
}
.uploade_images_wrapper .wrapps_upload_img {
    float: left;
    width: 33.33%;
}
.uploade_images_wrapper .wrapps_upload_img span {
    float: left;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    text-align: left;
    margin: 0 0 5px;
    display: flex;
    justify-content: space-between;
}
.uploade_images_wrapper .wrapps_upload_img span .bi-trash3-fill {
    color: red;
    cursor: pointer;
}
.send_btn {
    border: 0px !important;
    padding: 5px 8px !important;
    font-size: 13px !important;
    background-color: #0E0E30 !important;
    color: #fff !important;
    border-radius: 0px !important;
    position: relative;
    z-index: 9;
    overflow: hidden;
    border: 0px !important;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
}
.send_btn::before {
    content: "";
    float: left;
    width: 100%;
    height: 100%;
    background-color: #10b068;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(100%);
    -webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;
    z-index: -1;
}
.send_btn.active::before {
    transform: translateX(0%);
}
.send_btn:hover:before {
    transform: translateX(0%);
    -webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;
}



.donwload_btn {
    border: 0px !important;
    padding: 5px 8px !important;
    font-size: 13px !important;
    background-color: #10b068 !important;
    color: #fff !important;
    border-radius: 0px !important;
    position: relative;
    z-index: 9;
    overflow: hidden;
    border: 0px !important;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
}
.donwload_btn .download_progress_bra {
    float: left;
    width: 100%;
    height: 100%;
    background-color: #0E0E30;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    padding: 8px;
    -webkit-transition: 0.5s;-moz-transition: 0.5s;-o-transition: 0.5s;-ms-transition: 0.5s;transition: 0.5s;
}
.donwload_btn.active {
    opacity: 1 !important;
}
.filter_stock_inward {
    float: left;
    width: 100%;
}
.filter_stock_inward.active {
    width: auto;
    position: absolute;
    right: 11px;
    top: 67px;
}
.active_user td, .active_user:hover {
    background-color: #eee !important;
    font-style: italic;
    font-weight: 500;
}
.order_type_select {
    float: left;
    width: 100%;
    position: relative;
}
.order_type_select small {
    font-size: 11px;
    float: left;
    width: auto;
    position: absolute;
    left: 6px;
    top: -5px;
    background-color: #fff;
    padding: 0px 4px;
}
.order_type_select select {
    padding: 6px 12px 2px 9px !important;
    border-radius: 12px;
    font-size: 12px;
}
.error_note_bundle_wrapper {
    float: left;
    width: 80%;
    background-color: #fbebeb;
    border-left: 3px solid #dc3545;
    padding: 15px 15px;
    display: flex;
    align-items: center !important;
    column-gap: 9px;
    border-radius: 8px;
}
.error_note_bundle {
    font-weight: 400;
    font-size: 14px;
    margin: 0px;
    float: left;
    width: 90%;
    line-height: 20px;
    color: #dc3545;
    display: flex;
    align-items: center;
    column-gap: 8px;
}
.error_note_bundle .bi {
    font-size: 16px;
}
.error_note_bundle_wrapper .Resetbtnbundle {
    border-radius: 6px !important;
}
#Resetbtnbundle {
    margin-top: 0px !important;
}
.Courier_wrapper_image img {
    width: 175px;
    margin: 15px auto 20px;
}
.Courier_wrapper_image p {
    margin: 0px;
    font-size: 16px;
    line-height: 22px;
}
.Courier_wrapper_modal .modal-dialog {
    max-width: 410px;
}
#Courier_wrapper_modal .modal-content {
    border-radius: 30px !important;
}
#Courier_wrapper_modal .modal-content .modal-header .modal-title {
    font-size: 21px;
    text-align: center;
}
.Courier_wrapper_modal .modal-footer {
    justify-content: center;
}
#Courier_wrapper_modal .modal-footer .btn {
    border-radius: 30px !important;
    display: flex;
    align-items: center;
    column-gap: 4px;
    font-size: 15px;
}
#Courier_wrapper_modal .modal-footer .btn.addbtncmn {
    background-color: #ff0000 !important;
    border-color: #ff0000 !important;
}
#Courier_wrapper_modal .modal-footer .btn.addbtncmn img {
    width: 17px;
}
.bundle_order_active:hover, .bundle_order_active td {
    background-color: rgba(255, 165, 0, 0.2) !important;
}
.is_odi_order_active td {
    background-color: rgba(245, 133, 133, 0.32) !important;
}
.is_courier_pin_code_order_active td {
    background-color: #f3b70285 !important;
}
.order_bunddle_seelct {
    margin-right: 10px;
}
.order_bunddle_seelct .dropdown .btn {
    border-radius: 0px !important;
    background-color: transparent !important;
    border-color: #ddd !important;
    color: #000 !important;
    padding: 6.5px 10px;
    font-size: 14px;
}
.order_bunddle_seelct .dropdown .btn::before {
    content: "";
    float: left;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    margin: 7px 6px 0px 0px;
    display: none;
}
.order_bunddle_seelct .dropdown.order_bunlde_active_Bundle_orders #dropdown-basic-button {
    background-color: rgba(255, 165, 0, 0.2) !important;
    border-color: orange !important;
}
.order_bunddle_seelct .dropdown.order_bunlde_active_Bundle_orders .btn::before {
    background-color: rgba(255, 165, 0, 0.2);
    border: 1px solid orange;
}
.order_bunddle_seelct .dropdown.order_bunlde_active_All_Orders .btn::before {
    background-color: #eee;
    border: 1px solid #ddd;
}
.order_bunddle_seelct .dropdown .dropdown-menu a.All_Orders span {
    border: 1px solid #ddd !important;
}
.order_bunddle_seelct .dropdown .dropdown-menu a.Bundle_orders span {
    border: 1px solid orange !important;
}
.order_bunddle_seelct .dropdown .dropdown-menu {
    border-radius: 0px;
    border-color: #ddd;
    padding: 0px;
    min-width: 170px;
}
.clearall_permissions_btn {
    position: absolute;
    right: 58px;
    top: 18px;
    border-radius: 30px !important;
    font-size: 14px !important;
}
.order_bunddle_seelct .dropdown .dropdown-menu a {
    font-size: 13px;
    padding: 10px 14px;
    line-height: normal;
}
.order_bunddle_seelct .dropdown .dropdown-menu a span {
    float: left;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    margin: 5px 6px 0px 0px;
}
.order_bunddle_seelct .dropdown .dropdown-menu a:hover {
    background-color: #f8f8f8 !important;
    color: #333 !important;
}
.order_ODA_bunddle_seelct .dropdown .btn::before {
display: block !important;
}
.order_ODA_bunddle_seelct .dropdown.ODA_order_active_All_Orders .btn::before,
.order_ODA_bunddle_seelct .dropdown.ODA_order_active_Normal_Pincode .btn::before {
    background-color: #eee;
    border: 1px solid #ddd;
}
.order_ODA_bunddle_seelct .dropdown.ODA_order_active_ODA_Pincode .btn::before,
.order_ODA_bunddle_seelct .dropdown.ODA_order_active_ODA_Pincode .btn {
    background-color: rgba(245, 133, 133, 0.32) !important;
    border: 1px solid rgba(245, 133, 133, 0.32) !important;
}
.order_ODA_bunddle_seelct .dropdown.ODA_order_active_Serviceability_missing .btn::before,
.order_ODA_bunddle_seelct .dropdown.ODA_order_active_Serviceability_missing .btn {
    background-color: rgba(243, 183, 2, 0.52) !important;
    border: 1px solid rgba(243, 183, 2, 0.52) !important;
}
.date_time_stamp {
    float: left;
    width: auto;
    position: absolute;
    right: 201px;
    top: 1px;
    font-size: 14px;
    background-color: #eee;
    padding: 8px 14px 8px 14px;
    border-radius: 30px;
}
.date_time_stamp label {
    font-weight: 500;
    margin: 0 8px 0px 0px;
}
.outstanding_filters_datetime {
    float: left;
    width: 100%;
    position: relative;
}
.reassign_info {
    float: left;
    width: auto;
    font-size: 14px;
    background-color: #f2f2f2;
    padding: 10px 15px;
    border-radius: 10px;
}
.reassign_info .bi-info-circle-fill {
    font-size: 12px;
}
.old_warehouse_details {
    float: left;
    width: 100%;
    padding: 10px 10px;
    background-color: #f5f3f3;
    font-size: 13px;
    border: 1px solid #ddd;
}
.reassign_warehouse_wrapper {
    float: left;
    width: 100%;
    border: 1px solid #ddd;
    padding: 12px 11px 17px;
    margin: 0 0 15px;
    position: relative;
    border-radius: 8px;
}
.reassign_warehouse_wrapper .border_right {
    float: left;
    width: 48px;
    height: 50%;
    border: 5px solid #0E0E30;
    position: absolute;
    right: -49px;
    top: 58px;
    border-left: 0px;
    border-radius: 0px 15px 15px 0px;
}
.reassign_warehouse_wrapper .border_right i {
    float: left;
    width: auto;
    position: absolute;
    left: -18px;
    bottom: -25px;
    color: #0E0E30;
    font-size: 30px;
}
.reassign_warehouse_wrapper .col-md-12:last-child .mb-3 {
    margin-bottom: 0px !important;
}
.stocklevel_so_approval_modal .modal-dialog {
    max-width: 680px;
}
.UploadCmnBTNCommon {
    position: relative;
}
.UploadCmnBTNCommon .spinner-border {
    float: right;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 10px;
}
.commonModalAttachRemarks .modal-dialog {
    max-width: 430px;
}
.add_remark {
    padding: 0px;
    border: 0px !important;
    box-shadow: none !important;
}
.warehousePriorityCl {
    background-color: #CCFFCC !important;
}
.warehousePrioritydanger {
    background-color: #FFCCCC !important;
}
#stock_level_so_approval tr {
    background: transparent !important;
}
.loader_main_button {
    float: left;
    width: auto;
    margin: 5px 0 0 14px !important;
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.loader_main_button .loader_courier .spinner-border {
    width: 25px;
    height: 25px;
}
.load_more {
    font-size: 13px !important;
    background-color: #0E0E30 !important;
    color: #fff !important;
    padding: 5px 11px !important;
    border-radius: 30px !important;   
}
.Check_Serviceable_tableView .table {
    margin-bottom: 0px;
}
.Check_serviceability_btn {
    font-size: 13px !important;
    background-color: #10b068 !important;
    color: #fff !important;
    padding: 5px 11px !important;
    border-radius: 30px !important;
    float: right;
    margin: 5px 0 0;
}
.Check_serviceability_btn:hover {
    background-color: #0E0E30 !important;
    border-color: #0E0E30 !important;
}
.order_bunddle_checkboxes {
    float: left;
    width: auto;
    margin: 6px 12px 0px 0px;
}
.order_bunddle_checkboxes .form-label {
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-size: 15px;
}
.order_bunddle_checkboxes .form-label div {
    width: auto;
    float: left;
}
.order_bunddle_checkboxes .form-label div input {
    border: 1px solid #dee2e6 !important;
}
.search_Navigations_wrapper .btn {
    padding: 0px;
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
    width: 100% !important;
}
.search_Navigations_wrapper .btn::after {
    display: none;
}
.search_Navigations_wrapper .btn .bi-x-circle-fill {
    position: absolute;
    cursor: pointer;
    right: 38px;
    top: 11px;
    z-index: 9;
    font-size: 12px;
    color: #dc3545;
}
.search_Navigations_wrapper .btn input {
    box-shadow: none !important;
    border-radius: 25px;
    padding: 7px 50px 7px 40px;
    font-size: 14px;
    height: 40px;
    background-image: url("../img/menu-dots.png");
    background-repeat: no-repeat;
    background-position: 97% center;
    background-size: 25px;
    cursor: pointer;
}
.search_Navigations_wrapper .dropdown {
    box-shadow: none !important;
    width: 335px;
}
.search_Navigations_wrapper .dropdown .bi-search {
    position: absolute;
    left: 12px;
    z-index: 99;
    color: #333;
    top: 7px;
    font-size: 18px;
}
.search_Navigations_wrapper .dropdown .dropdown-menu {
    padding: 5px 0px;
    overflow: auto;
    max-height: 300px !important;
}
.search_Navigations_wrapper .dropdown .dropdown-item {
    float: left;
    width: 100%;
    position: relative;
    padding: 7px 7px 7px 25px;
}
.search_Navigations_wrapper .dropdown .dropdown-item .bi-bicycle {
    font-size: 22px;
    position: absolute;
    right: 14px;
    top: 8px;
    color: #e1e1e1;
}
.search_Navigations_wrapper .dropdown .dropdown-item::before {
    content: "";
    float: left;
    height: 6px;
    width: 6px;
    background-color: #333;
    position: absolute;
    left: 13px;
    top: 14px;
    border-radius: 100%;
}
.module_not_found {
    padding: 7px 7px 7px 35px !important;
}
.module_not_found::before {
    content: "\F6B6" !important;
    font-family: 'bootstrap-icons';
    height: auto !important;
    width: auto !important;
    background-color: transparent !important;
    color: red;
    left: 14px !important;
    top: 10px !important;
}
.search_Navigations_wrapper .dropdown .dropdown-item:active, 
.search_Navigations_wrapper .dropdown .dropdown-item:hover,
.search_Navigations_wrapper .dropdown .dropdown-item:focus {
    background-color: #eee !important;
    color: #333 !important;
}
.search_Navigations_wrapper .dropdown .dropdown-item:hover .bi-bicycle,
.search_Navigations_wrapper .dropdown .dropdown-item:focus-visible .bi-bicycle {
    color: #10b068 !important;
}
.search_Navigations_wrapper .dropdown .dropdown-item:focus-visible {
    box-shadow: none !important;
    border: 0px;
    outline: none;
}
.search_Navigations_wrapper .dropdown div {
    float: left;
    width: 100%;
    font-size: 14px;
    line-height: normal;
}
.search_Navigations_wrapper .dropdown small {
    float: left;
    width: 100%;
    font-size: 10px;
    line-height: normal;
    text-align: left;
    color: #10b068;
    font-weight: 500;
    position: relative;
}
.search_Navigations_wrapper .dropdown small::after {
    content: "";
}
.search_Navigations_wrapper {
    float: right;
    width: auto;
    margin: 0 20px 0px 0px;
    position: relative;
}
.sales_person_uplaod_downlo_wrapper {
    float: left;
    width: auto;
    margin-right: 9px;
}
.import_monthly_target {
    font-size: 14px !important;
    border-radius: 25px !important;
    padding: 9.5px 20px !important;
    background-color: #10b068 !important;
    border: 0px !important;
    color: #fff !important;
    position: relative;
    overflow: hidden;
    z-index: 9;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
}
.import_monthly_target::after {
    content: "";
    float: left;
    width: 100%;
    height: 100%;
    background-color: #0E0E30 !important;
    border-color: #0E0E30 !important;
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: -1;
    transform: translateX(-100%);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
    position: relative;
}
.import_monthly_target:hover::after {
    transform: translateX(100%);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
}
.import_monthly_target input[type="file"] {
position: absolute;
left: 0;
top: 0;
visibility: hidden;
}
.import_monthly_target .spinner-border {
height: 17px;
width: 17px;
float: left;
margin: 2px 0 0 0;
}
.sales_person_uplaod_downlo_wrapper .btn.sales_download_file {
    background-color: #0E0E30 !important;
    color: #fff !important;
    border-radius: 0px 20px 20px 0px;
    padding: 7px 11px 7px 25px;
    margin: 0 0 0 -19px;
}
.splits_wrapper_list {
    float: left;
    width: 30%;
    position: fixed;
    right: 10px;
    top: 68px;
    z-index: 999;
    background-color: #fff;
    border: 1px solid #0a58ca;
    border-radius: 6px;
    overflow: hidden;
}
.splits_wrapper_list.react-draggable-dragging {
    cursor: grabbing !important;
}
.splits_wrapper_list.react-draggable-dragged {
    cursor: grab;
}
.splits_wrapper_list .splits_wrapper_header {
    float: left;
    width: 100%;
    padding: 10px 55px 10px 10px;
    border-bottom: 1px solid #0a58ca;
    background-color: #e8f1ff !important;
    color: #0a58ca !important;
}
.splits_wrapper_list .splits_wrapper_header h4 {
    margin: 0px;
    font-size: 15px;
}
.splits_wrapper_list .splits_wrapper_table {
    float: left;
    width: 100%;
    padding: 10px;
    max-height: calc(100vh - 120px);
}
.splits_wrapper_list.error {
    border-color: #dc3545 !important;
}
.splits_wrapper_list.error .splits_wrapper_header {
    background-color: #fbebeb !important;
    color: #dc3545 !important;
    border-color: #dc3545 !important;
}
.btn-view {
    padding: 9px 15px !important;
    line-height: normal !important;
    font-size: 14px !important;
    border: 1px solid #ddd !important;
    margin: 4px 0 0 !important;
}
.splits_wrapper_header .modal_setting {
    float: right;
    width: auto;
    column-gap: 6px;
    display: flex;
    font-size: 17px;
    position: absolute;
    right: 10px;
    top: 7px;
}
.splits_wrapper_header .modal_setting .bi {
    cursor: pointer;
}
.splits_wrapper_table .table thead tr th, .splits_wrapper_table .table tbody tr td {
    font-size: 13px;
    padding: 7px 10px;
}
.search_Navigations_wrapper .dropdown .dropdown-item strong {
    font-weight: 600 !important;
    color: #000 !important;
}

/* Hide scrollbar when not needed */
.table-responsive::-webkit-scrollbar, .ScrollbarsSidebar .view::-webkit-scrollbar {
    display: none;              /* Hide scrollbar in WebKit browsers */
}

.table-responsive {
    scrollbar-width: none;      /* Hide scrollbar in Firefox */
    -ms-overflow-style: none;   /* Hide scrollbar in Internet Explorer and Edge */
}
.orderCncelModalCMN .modal-title .bi {
    color: #10b068;
}

.verify_btn {
    width: auto !important;
    padding: 7.5px 14px !important;
    font-size: 14px !important;
}
#reset_btn {
    background-color: #080606 !important;
    border-color: #080606 !important;
    width: auto;
    padding: 7.5px 14px !important;
    margin-left: 10px;
    font-size: 14px;
}
.verifySuccessErrorModal .modal-dialog {
    max-width: 410px;
}
.verifySuccessErrorModal .modal-body {text-align: center;}
.verifySuccessErrorModal .modal-body .bi-check2-circle {
    float: left;
    width: 100%;
    margin: 0 0 11px;
    color: #00AC20;
    font-size: 85px;
    line-height: 85px;
}
.verifySuccessErrorModal .modal-body h5 {
    float: left;
    width: 100%;
    font-size: 20px;
    margin: 0 0 10px;
}
.verifySuccessErrorModal .modal-body p {
    line-height: normal;
    font-size: 15px;
    margin: 0px;
}
.verifySuccessErrorModal .modal-body .verify_deta {
    float: left;
    width: 100%;
    text-align: left;
    font-size: 13px;
    border-top: 1px solid #ddd;
}
.verifySuccessErrorModal .modal-body .verify_deta ul {
    float: none;
    width: 310px;
    margin: 10px auto 0;
    padding: 0px;
    display: table;
}
.verifySuccessErrorModal .modal-body .verify_deta ul li {
    float: left;
    width: 100%;
    list-style: none;
    margin: 0 0 3px;
}
.verifySuccessErrorModal .modal-body .verify_deta ul li label {
    float: left;
    width: 140px;
    font-weight: 500;
}
.verifySuccessErrorModal .modal-footer {
    padding: 5px 10px;
    text-align: center;
    display: inline-block;
}
.proceed_btn {
    background-color: #080606 !important;
    border-color: #080606 !important;
    padding: 9px 18px !important;
    font-size: 15px !important;
    border-radius: 0px !important;
    position: relative;
}
.verifySuccessErrorModal .modal-header h4{
    font-size: 18px;
    margin: 0px;
}
.verifySuccessErrorModal .btn-close {
    box-shadow: none !important;
    outline: none !important;
    position: absolute;
    top: 11px;
    right: 11px;
    cursor: pointer;
    z-index: 99;
}
.verifySpan {
    float: right;
    width: auto;
    background-color: #00ac3e;
    color: #fff;
    border-radius: 30px;
    padding: 3px 9px 3px 4px;
    font-size: 12px;
    margin: -1px 0 0px 7px;
}
.verifySpan.verifySpanerror {
    background-color: #f60000 !important;
}
.banks_details_list {
    float: left;
    width: 100%;
    margin: 0px;
    padding: 0px 18px;
}
.banks_details_list ul {
    text-align: left;
    padding: 0px;
    font-size: 14px;
    float: none;
    width: auto;
    margin: 0 auto 0;
    display: table;
}
.banks_details_list li label {
    font-weight: 500;
}
.bank_details_alerts {
    font-size: 14px;
    padding: 10px 13px !important;
}
.createScheme_wrapper .react-datepicker-popper {
    z-index: 99;
}
.addRangebtn {
    padding: 7.5px 10px !important;
    font-size: 14px !important;
}
.datepickField.datepickFieldSchome input {
    background-position: 95% center;
}
.incentive_slab_Main_section .card-header-New .card-header-right .userLimitselect {
    display: none;
}
.otp_wrappper {
float: none;
width: 75%;
display: flex;
justify-content: center;
column-gap: 8px;
margin: 0 auto 10px;
}
.otp_wrappper input {
text-align: center;
}
.loader_wrapper {
float: right;
width: auto;
margin: 7px 9px 0px;
}
.loader_wrapper .spinner-border {
height: 24px;
width: 24px;
border-width: 3px;
}
.ticktes_history_details_modal .modal_logs_headers span {
float: left;
width: 100%;
font-size: 15px;
line-height: normal;
font-weight: 400;
display: inline-block;
}
.ticktes_history_details_modal .modal-dialog {
max-width: 380px;
}
.ticktes_history_details_modal ul {
padding-left: 7px;
margin: 0px
}
.ticktes_history_details_modal ul li {
float: left;
width: 100%;
color: #606060;
list-style: none;
position: relative;
padding-left: 35px;
margin-bottom: 12px;
}
.ticktes_history_details_modal ul li::before {
content: "\F287";
font-family: 'bootstrap-icons';
color: #606060;
position: absolute;
left: 0px;
font-size: 14px;
top: 3px;
}
.ticktes_history_details_modal ul li::after {
content: "";
float: left;
width: 2px;
height: 100%;
background-color: #606060;
position: absolute;
left: 6px;
bottom: -20px;
}
.ticktes_history_details_modal ul li:last-child::after {
display: none;
}
.ticktes_history_details_modal ul li h5 {
font-size: 17px;
margin: 0 0 1px;
line-height: normal;
}
.ticktes_history_details_modal ul li span {
font-size: 14px;
}
.fba_order_logs_Main_section {
    float: left;
    width: 100%;
    position: relative !important;
}
.fba_order_logs_Main_section .sales_person_uplaod_downlo_wrapper {
    position: absolute;
    left: 29%;
    top: 0;
}
.fba_order_logs_Main_section .sales_person_uplaod_downlo_wrapper .import_monthly_target {
    padding: 9px 17px !important;
    font-size: 13px !important;
}
.MainHeader.AfterSalesHeaderSteps {
    display: flex;
    justify-content: space-between;
}
.MainHeader.AfterSalesHeaderSteps h4 {
    float: left;
    width: auto;
    margin: 0px;
    font-size: 15px;
    line-height: normal;
}
.MainHeader.AfterSalesHeaderSteps .bi-backspace-reverse-fill {
    position: relative;
    top: 1px;
}
#confirm_order_btn {
    width: auto;
    margin: 0px !important;
}
.godown-select__menu-portal {
    z-index: 99 !important;
}
.FranchiseWallet.sales_order_tab_heading {
    padding: 0px;
    border: 0px;
    position: absolute;
    right: 0;
    width: auto;
}
.informative_text {
    line-height: 16px;
    display: flex;
    float: left;
    width: 100%;
    font-size: 13px;
    column-gap: 5px;
    margin: 2px 0 0;
}
.informative_text .bi {
    font-size: 11px;
    line-height: normal;
    float: left;
    width: auto;
    margin: 2px 0 0 0;
}
.warranty_order_cols {
    float: left;
    width: 100%;
    margin: 6px 0 0;
}
.warranty_order_cols .form-label {
    margin: 0px;
    width: auto;
}
.warranty_order_cols .form-label div {
    display: flex;
    float: left;
    width: auto;
    margin-right: 7px;
}
.warranty_order_cols .form-label div .form-check-input {
    width: 15px;
    height: 16px !important;
}
#createSpares {
    margin: 0px !important;
    padding: 6px 10px !important;
    width: auto;
}
#createSparesClear {
    margin: 0px !important;
    padding: 6px 10px !important;
    width: auto;
    background-color: #0e0e30 !important;
    border-color: #0e0e30 !important;
    margin-left: 10px !important;
}
.bottom_button_design {
    float: left;
    width: 100%;
    text-align: center;
}
.Compatible_table tbody tr:last-child td {
    border-bottom: 0px !important;
}
.spares_allready_label {
    float: left;
    width: 100%;
    font-size: 17px;
    color: red;
    border-bottom: 1px solid #ddd;
    padding-bottom: 9px;
    margin: 0 0 12px;
}
#delete_all {
    background: transparent !important;
    color: red;
    border-color: red !important;
}
.error_spare_item {
    background-color: #ffc3c3 !important;
}
.statusof_spare_Items {
    float: left;
    width: auto;
    padding: 0px;
}
.statusof_spare_Items li {
    float: left;
    width: auto;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 7px;
    font-weight: 500;
    font-size: 14px;
    margin-right: 25px;
}
.statusof_spare_Items li:last-child {
    margin-right: 0px;
}
.statusof_spare_Items span{
    float: left;
    width: 18px;
    height: 18px;
    border-radius: 4px;
}
.statusof_spare_Items .error {
    background-color: #ffc3c3;
    border: 1px solid #ffc3c3;
}
.statusof_spare_Items .success {
    background-color: #fff;
    border: 1px solid #ddd;
}
.labelAddOnFields {
    position: relative;
}
.labelAddOnFields small {
    font-size: 11px;
    padding: 0px 5px;
    background-color: #fff;
    position: absolute;
    left: 18px;
    top: -6px;
}
.view_more_btn {
    border: 1px solid #0E0E30 !important;
    background: transparent;
    font-size: 14px !important;
    color: #0E0E30 !important;
    padding: 3px 10px !important;
}
.view_more_btn i {
    line-height: normal;
    font-size: 16px;
    margin-right: 4px;
}
.faq_wrapper {
    float: left;
    width: 100%;
    text-align: left;
    font-size: 17px;
}
.faq_wrapper label {
    font-weight: 500;
    margin-bottom: 7px;
    line-height: normal;
}
.faq_wrapper p {
    line-height: 22px;
    font-size: 16px;
    margin-bottom: 0px !important;
}


/* privacy policy page css */
.privacy_policy_wrapper {
    float: left;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}
.privacy_policy_wrapper .heaing_main {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 40px;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
    margin: 0 0 32px;
}
.privacy_policy_wrapper .heaing_main img {
    width: 100px;
}
.privacy_policy_wrapper .heaing_main h2 {
    font-size: 30px;
    margin: 0px;
}
.privacy_policy_wrapper .privyc_header {
    float: left;
    width: 100%;
    margin: 0 0 30px;
}
.privacy_policy_wrapper .privyc_header h4 {
    float: left;
    width: 100%;
    margin: 0px 0px 15px;
    font-size: 17px;
}
.privacy_policy_wrapper .privyc_header p {
    float: left;
    width: 100%;
    margin: 0 0 0;
}
.privacy_policy_wrapper .privyc_body {
    float: left;
    width: 100%;
    margin: 0 0 30px;
}
.privacy_policy_wrapper .privyc_body h4 {
    float: left;
    width: 100%;
    margin: 0px 0px 15px;
    font-size: 17px;
}
.privacy_policy_wrapper .privyc_body p {
    float: left;
    width: 100%;
    margin: 0 0 0;
}
.privacy_policy_wrapper ul, .privacy_policy_wrapper  li {
    float: left;
    width: 100%;
}
.privacy_policy_wrapper  ul li {
    float: left;
    width: 100%;
    margin-bottom: 6px;
    font-size: 15px;
}
.privacy_policy_wrapper .privacy_main_body {
    float: left;
    width: 100%;
    padding: 0px 40px;
    box-sizing: border-box;
}
/* privacy policy page css */

/* tickets verify css here */
.verify_tickets_wrapper {
    float: left;
    width: 100%;
    position: relative;
    display: flex;
    column-gap: 5px;
}
.verify_tickets_wrapper .verify_wrapper {
    float: left;
    width: 100%;
    position: relative;
}
.verify_tickets_wrapper .verify_tickets_wrapper_btns {
    float: left;
    width: auto;
    display: flex;
    column-gap: 2px;
}
.verify_tickets_wrapper .verify_tickets_wrapper_btns .spinner-border {
    width: 19px;
    height: 19px;
    border-radius: 100%;
    border-width: 3px;
    float: left;
    margin: 6px 5px 0px 2px;
}
.verify_tickets_wrapper .verify_tickets_wrapper_btns .verify_tickets_btn {
    margin-right: 3px;
}
.verify_tickets_wrapper .verify_tickets_wrapper_btns .btn {
    font-size: 13px;
    padding: 5px 10px;
    width: auto;
}
.verify_tickets_wrapper .verify_tickets_wrapper_btns .reset_btn {
    background-color: #0E0E30 !important;
    border-color: #0E0E30 !important;
}
.verify_tickets_wrapper .verify_loader {
    float: left;
    width: auto;
    position: absolute;
    right: 8px;
    top: 8px;
}
.verify_tickets_wrapper .verify_loader .spinner-border {
    float: left;
    width: 22px;
    height: 22px;
    border-width: 3px;
}
.verify_tickets_wrapper .verify_wrapper input {
    padding: 0.375rem 35px 0.375rem 0.75rem;
}
.tickets_verifications_wraps ul li {
    float: left;
    width: 100%;
    font-size: 15px;
    padding-bottom: 4px;
}

.tickets_verifications_wraps ul {
    float: left;
    width: 100%;
    margin-bottom: 0px;
    padding-left: 20px;
}

.tickets_verifications_wraps ul li label {
    float: left;
    width: auto;
    font-weight: 500;
}
/* tickets verify css here */

/* Firefox-specific CSS */
@supports (-moz-appearance: none) {
    .ScrollbarsSidebar.ScrollbarsSidebar-active .view {
        margin-right: 2px !important;
    }
}
@-moz-document url-prefix() {
    .ScrollbarsSidebar.ScrollbarsSidebar-active .view {
        margin-right: 2px !important;
    }
}
@media screen and (-moz-images-in-menus:0) {
    .ScrollbarsSidebar.ScrollbarsSidebar-active .view {
        margin-right: 2px !important;
    }
}

/* dark mode css */
.adminmain.dark .head_section, .adminmain.dark .sidebarWrapper .sidebar {
background-color: #222428;
color: #fff;
border-color: #000;
}
.adminmain.dark .panelContentwrapper,
.adminmain.dark .order_warning_error{
background-color: #222428;
color: #fff;
}
.adminmain.dark .dropdownHeader .dropdown .dropdown-toggle, 
.adminmain.dark .sidebarWrapper .sidebar .flex-column .nav-link,
body.dark .selectMultiselect .css-13cymwt-control, 
body.dark .selectMultiselect .css-1jqq78o-placeholder, 
body.dark .selectMultiselect .css-1dimb5e-singleValue{
color: #fff !important;
}
.adminmain.dark .navLogo {
border-color: #000;
}
.adminmain.dark .collapnseDrop {
background-color: #1e1e1e;
border-color: #1e1e1e;
}
.adminmain.dark .fileloader {
    background-color: #333;
    box-shadow: 0px 0px 17px -3px #000000 !important;
}
.adminmain.dark .btnGroupswraps .Resetbtn {
    background-color: #1b1b1b !important;
    border-color: #1b1b1b !important;
}
.adminmain.dark .fileloader img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(102%) contrast(102%);
}
.adminmain.dark .collapnseDrop li a {
color: #fff !important;
}
.adminmain.dark .sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a:hover, 
.adminmain.dark .sidebarWrapper .sidebar .flex-column .collapnseDrop ul li a.active {
background-color: #383839 !important;
color: #fff;
}
.adminmain.dark .searchBoxwithbtn .form-control,
.adminmain.dark .card-header-right select,
.adminmain.dark .date_time_stamp,
.adminmain.dark .godown-select--is-disabled .godown-select__control,
.adminmain.dark .GSTAmounttotal textarea.form-control,
.adminmain.dark .defaultvalueField,
.adminmain.dark .formloginset textarea,
body.dark .searchBoxwithbtn .form-control,
body.dark .card-header-right select,
body.dark .date_time_stamp,
.adminmain.dark .order_bunddle_seelct .dropdown .btn,
.adminmain.dark .order_bunddle_seelct .dropdown .dropdown-menu,
.adminmain.dark .order_bunddle_seelct .dropdown .dropdown-menu a,
body.dark .formloginset input,
.adminmain.dark .uplaodedFilename,
.adminmain.dark .settting_Staus_wrapper .settting_Staus .statusPercentafe input,
.adminmain.dark .tableView tbody tr td input,
body.dark .tableView tbody tr td input,
body.dark textarea.form-control,
body.dark .OrderDetailsModal input {
background-color: #1b1b1b;
border: 1px solid hsla(0, 0%, 100%, .1) !important;
color: #fff !important;
}
.adminmain.dark .tableView tbody tr.active_user td {
background-color: #494949 !important;
}
.adminmain.dark .formloginset input:focus {
background-color: #1b1b1b !important;
border: 1px solid hsla(0, 0%, 100%, .1) !important;
color: #fff !important;
}
.adminmain.dark .FranchiseWallet .Franchise_name,
.adminmain.dark .GSTAmounttotal li label,
.adminmain.dark .GSTAmounttotal li,
.adminmain.dark .FranchiseWallet .backbtn {
    color: #fff;
}
.adminmain.dark .searchBoxwithbtn .form-control::placeholder,
body.dark .searchBoxwithbtn .form-control::placeholder,
.adminmain.dark .formloginset textarea::placeholder,
.adminmain.dark .formloginset input::placeholder,
.adminmain.dark .datepickField input::placeholder,
.adminmain.dark .selectMultiselect .godown-select__placeholder,
body.dark .formloginset input::placeholder,
body.dark textarea.form-control::placeholder {
    color: #ccc;
}
.adminmain.dark .searchInfo, 
body.dark .searchInfo{
background-color: transparent;
}
.adminmain.dark .tableView tbody tr td,
.adminmain.dark .tableView thead tr th, 
.adminmain.dark .table-bordered>:not(caption)>*,
.adminmain.dark .tablecollapsewraps table th,
.adminmain.dark .totaltd th,
body.dark .tableView tbody tr td,
body.dark .tableView thead tr th, 
body.dark .table-bordered>:not(caption)>*,
body.dark .tablecollapsewraps table th,
body.dark .OrderDetailsModal tbody tr td,
body.dark .OrderDetailsModal thead tr th,
body.dark .OrderDetailsModal tr td .logo,
body.dark .OrderDetailsModal tr td,
body.dark .OrderDetailsModal tr td h5, 
body.dark .OrderDetailsModal tr td h2,
body.dark .OrderDetailsModal tr td p,
body.dark .OrderDetailsModal tbody tr th, 
body.dark .table-bordered>:not(caption)>*,
body.dark .tablecollapsewraps table th,
.adminmain.dark .filtercollapsebtn,
body.dark .account-table> tr>td, 
body.dark .account-table tr>td, 
body.dark .account-table tr>th,
.adminmain.dark .dispatchTbale_ui th {
    background-color: #222428;
    border-color: hsla(0, 0%, 100%, .1) !important;
    color: #fff !important;
}
body.dark #invoice_model .OrderDetailsModal td, body.dark .account-table> tr:first-child>td, body.dark .account-table> tr:first-child>th {
    border-color: #333 !important;
}
body.dark .totaltd th {
    background-color: #222428 !important;
    border-color: hsla(0, 0%, 100%, .1) !important;
    color: #fff !important;
}
body.dark #invoice_model .OrderDetailsModal td .text-capitalize, 
body.dark #invoice_model .OrderDetailsModal td strong {
    background-color: transparent;
    color: #fff;
}
body.dark .skipbtn {
    background-color: #333 !important;
}
.adminmain.dark .smallLabelData small,
.adminmain.dark .smallLabelData .clearbtn {
    background-color: #222428 !important;   
    color: #fff !important;
}
.adminmain.dark .HeaderDivider h6.subtitleForm {
    background-color: #222428 !important;   
    color: #fff !important;
    border-color: hsla(0, 0%, 100%, .1) !important;
}
.adminmain.dark .smallLabelData,
.adminmain.dark .MainHeader,
.adminmain.dark .HeaderDivider,
body.dark .modal-footer {
    border-color: hsla(0, 0%, 100%, .1) !important;
}
.adminmain.dark .tableView thead tr th,
body.dark .tableView thead tr th {
    background-color: #1b1b1b !important;   
}
.adminmain.dark .filtr_Form,
body.dark .filtr_Form,
.adminmain.dark .FranchiseWallet,
body.dark .modal-header,
.adminmain.dark .formsearchList {
    border-color: hsla(0, 0%, 100%, .1) !important;
}
.adminmain.dark .edittable,
body.dark .edittable,
.adminmain.dark .selectMultiselect .css-13cymwt-control, 
.adminmain.dark .selectMultiselect .css-1jqq78o-placeholder, 
.adminmain.dark .selectMultiselect .css-1dimb5e-singleValue,
.adminmain.dark .add_remark,
body.dark .modal-invoice .btn.btn-icon {
    color: #fff !important;
}
.adminmain.dark .sidebarWrapper {
border-color: #222428;
}
.adminmain.dark .tableBolanchor, 
.adminmain.dark .tableView tbody .btn-link-color , 
.adminmain.dark .tableView tbody tr td .btn-link, 
.adminmain.dark .sotklevelbtn,
.adminmain.dark .selectBoxAnchor {
color: #10b068 !important;
}
.adminmain.dark,
.adminmain.dark .order_bunddle_seelct .dropdown .dropdown-menu a:hover {
    background-color: #333 !important;
}
.adminmain.dark .sidebarWrapper::after {
    background-color: #333;
}
.adminmain.dark .QauntityManage {
    background-color: #333;
}
.adminmain.dark .actionbtn img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(102%) contrast(102%);
}
.adminmain.dark .datepickField input,
body.dark .datepickField input {
    background-color: #1b1b1b;
    border: 1px solid hsla(0, 0%, 100%, .1) !important;
    color: #fff;
    background-image: url("../img/calendar-white.png") !important;
}
.adminmain.dark .formloginset select, 
.adminmain.dark .card-header-right select, 
body.dark .card-header-right select {
    background-color: #1b1b1b;
    border: 1px solid hsla(0, 0%, 100%, .1) !important;
    color: #fff;
    background-image: url("../img/caret-down-white.svg") !important;
}
.adminmain.dark .searchBoxwithbtn .searchclear {
    background-color: transparent !important;
}
.adminmain.dark .selectMultiselect .godown-select__control.css-13cymwt-control, 
.adminmain.dark .selectMultiselect .css-t3ipsp-control,
.adminmain.dark .selectMultiselect .godown-select--is-focused,
body.dark .selectMultiselect .godown-select__control.css-13cymwt-control, 
body.dark .selectMultiselect .css-t3ipsp-control,
body.dark .adminmain.dark .selectMultiselect .godown-select--is-focused,
.adminmain.dark .formloginset input,
.adminmain.dark .FranchiseWallet .FranchiseBlance_name,
.adminmain.dark .defaultvalueFieldaddress {
    background-color: #1b1b1b;
    border: 1px solid hsla(0, 0%, 100%, .1) !important;
    color: #fff;
}
.adminmain.dark .godown-select__menu,
.adminmain.dark .react-datepicker,
.adminmain.dark .react-datepicker .react-datepicker__header,
body.dark .godown-select__menu,
body.dark .react-datepicker,
body.dark .react-datepicker .react-datepicker__header {
    background-color: #1b1b1b;
    border: 1px solid hsla(0, 0%, 100%, .1) !important;
    color: #fff;
}
.adminmain.dark .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,
body.dark .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #1b1b1b !important;
}
.adminmain.dark .react-datepicker .react-datepicker__day:hover,
body.dark .react-datepicker .react-datepicker__day:hover {
    background-color: #333 !important;
}
.adminmain.dark .react-datepicker .react-datepicker__day,
.adminmain.dark .react-datepicker .react-datepicker__header .react-datepicker__current-month,
.adminmain.dark .react-datepicker .react-datepicker__header .react-datepicker__day-name,
body.dark .react-datepicker .react-datepicker__day,
body.dark .react-datepicker .react-datepicker__header .react-datepicker__current-month,
body.dark .react-datepicker .react-datepicker__header .react-datepicker__day-name,
.adminmain.dark .MainHeader .backbtn {
    color: #fff;
}
.adminmain.dark .godown-select__menu .godown-select__option,
body.dark .godown-select__menu .godown-select__option {
    background-color: #1b1b1b;
}
.adminmain.dark .godown-select__menu .godown-select__option:hover,
.adminmain.dark .godown-select__menu .godown-select__option:focus,
.adminmain.dark .godown-select__menu .godown-select__option--is-selected,
.adminmain.dark .godown-select__menu .godown-select__option--is-focused 
body.dark .godown-select__menu .godown-select__option:hover,
body.dark .godown-select__menu .godown-select__option:focus,
body.dark .godown-select__menu .godown-select__option--is-selected,
body.dark .godown-select__menu .godown-select__option--is-focused {
    background-color: #333 !important;
}
.adminmain.dark .godown-select__menu .godown-select__menu-list,
body.dark .godown-select__menu .godown-select__menu-list,
.adminmain.dark .dashboard_Main_section .MainHeader,
body.dark .dashboard_Main_section .MainHeader {
    scrollbar-color: #888 #3333; /* Thumb and track colors */
}
.adminmain.dark .godown-select__menu .godown-select__menu-list::-webkit-scrollbar-thumb,
body.dark .godown-select__menu .godown-select__menu-list::-webkit-scrollbar-thumb,
.adminmain.dark .dashboard_Main_section .MainHeader::-webkit-scrollbar-thumb,
body.dark .dashboard_Main_section .MainHeader::-webkit-scrollbar-thumb {
    background-color: #888 !important;
}
.adminmain.dark .godown-select__menu .godown-select__menu-list::-webkit-scrollbar-track,
body.dark .godown-select__menu .godown-select__menu-list::-webkit-scrollbar-track,
.adminmain.dark .dashboard_Main_section .MainHeader::-webkit-scrollbar-track,
body.dark .dashboard_Main_section .MainHeader::-webkit-scrollbar-track {
    background-color: #3333 !important;
}
.adminmain.dark .dropdownHeader .dropdown-menu,
body.dark .dropdownHeader .dropdown-menu,
.adminmain.dark .dropdownHeader .dropdown-menu .dropdown-item:focus,
body.dark .dropdownHeader .dropdown-menu .dropdown-item:focus {
    background-color: #434343;
    outline: none !important;
}
.adminmain.dark .dropdownHeader .dropdown-menu .dropdown-item,
body.dark .dropdownHeader .dropdown-menu .dropdown-item {
    color: #fff;
}
.adminmain.dark .dropdownHeader .dropdown-menu .dropdown-item:hover,
body.dark .dropdownHeader .dropdown-menu .dropdown-item:hover {
    background-color: #333 !important;
}
body.dark .commonModal .modal-content,
body.dark .OrderDetailsModal .modal-content,
body.dark .order_logs_wrapper .modal-content,
body.dark .modal-invoice .modal-content,
body.dark .errorDetailsModal .modal-content{
    background-color: #222428;
    border: 1px solid hsla(0, 0%, 100%, .1) !important;
    color: #fff;
}
#formloginset-main .form-control-plaintext {
    background-color: transparent;
    border: 0px !important;
}
.adminmain.dark .react-datepicker__day--disabled, 
.adminmain.dark .react-datepicker__month-text--disabled, 
.adminmain.dark .react-datepicker__quarter-text--disabled, 
.adminmain.dark .react-datepicker__year-text--disabled,
body.dark .react-datepicker__day--disabled, 
body.dark .react-datepicker__month-text--disabled, 
body.dark .react-datepicker__quarter-text--disabled, 
body.dark .react-datepicker__year-text--disabled {
    color: #ccc !important;
}
body.dark .modal-dialog .modal-content .modal-header .btn-close {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(102%) contrast(102%);
}
body.dark .head_cmn_btn {
    background-color: #fff !important;
    border-color: hsla(0, 0%, 100%, .1) !important;
    color: #333 !important;
}
body.dark #btn-groups-main-id.btn-groups-main .btn {
    border-color: #fff;
    color: #fff;
}
.adminmain.dark .order_type_select small {
    background-color: #222428;
}
.adminmain.dark .sidebarMain {
    background-color: #1a1919 !important;
    border-color: #1a1919 !important;
}
.adminmain.dark #UploadCmnBTN {
    background-color: #333 !important;
    border-color: #333 !important;
}
.adminmain.dark .paginationCustom li button {
    background: transparent;
    color: #fff;
}
.adminmain.dark .paginationCustom li:nth-child(2) button,
.adminmain.dark .paginationCustom li:nth-last-child(2) button {
    color: transparent !important;
    background: transparent;
}
.adminmain.dark .paginationCustom li:nth-child(2) button::before,
.adminmain.dark .paginationCustom li:nth-last-child(2) button::before {
    color: #fff !important;
}
body.dark .Toastify__toast--success {
    background-color: #28a745 !important;
    color: #ffffff !important;
}
body.dark .Toastify__toast--error {
    background-color: #dc3545 !important;
    color: #ffffff !important;
}
body.dark .Toastify__toast--success svg,
body.dark .Toastify__toast--error svg {
    color: #ffffff !important;
    fill: #ffffff !important;
}
body.dark .bundle_order_active:hover, body.dark .bundle_order_active td {
    background-color: rgba(255, 165, 0, 0.2) !important;
}
body.dark .is_odi_order_active td {
    background-color: rgb(235 77 77 / 32%) !important;
}
body.dark .is_courier_pin_code_order_active td {
    background-color: #ae830085 !important;
}
body.dark .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
    border-bottom-color: #545353 !important; 
}
body.dark .uplaodedFilename {
    background-color: #333 !important;
    border-color: #4e4e4e !important;
}
body.dark .uplaodedFilename .bi-x-circle-fill {
    background-color: #333 !important;
}
#UploadCmnBTN {
    background-color: #1b1b1b !important;
    border: 1px solid hsla(0, 0%, 100%, .1) !important;
}
body.dark .reassign_info {
    background-color: #333;
}
body.dark .reassign_warehouse_wrapper {
    border: 1px solid hsla(0, 0%, 100%, .1) !important;
}
body.dark .old_warehouse_details {
    background-color: #1b1b1b !important;
    border: 1px solid hsla(0, 0%, 100%, .1) !important;
}
body.dark .btn.button-1 {
    background-color: #333 !important; 
    border-color: #333 !important;
}
body.dark .paginationCustom li button {
    background: transparent;
    color: #fff;
}
body.dark .paginationCustom li:nth-child(2) button,
body.dark .paginationCustom li:nth-last-child(2) button {
    color: transparent !important;
    background: transparent;
}
body.dark .paginationCustom li:nth-child(2) button::before,
body.dark .paginationCustom li:nth-last-child(2) button::before {
    color: #fff !important;
}
body.dark .godown-select__multi-value {
    background-color: #333 !important;
}
body.dark .godown-select__multi-value .godown-select__multi-value__label {
    color: #fff !important;
}
body.dark .monthly_target_fields::placeholder {color: #fff !important;}
body.dark .addSalerperformBtn.salespersionClearbtn {
    background-color: #000 !important;
}
body.dark .search_Navigations_wrapper .dropdown .dropdown-menu {
    background-color: #333 !important;
    color: #fff !important;
}
body.dark .search_Navigations_wrapper .dropdown .dropdown-item {
    color: #fff !important;
}
body.dark .search_Navigations_wrapper .dropdown .dropdown-item::before {
    background-color: #fff !important;
}
body.dark .search_Navigations_wrapper .dropdown .dropdown-item .bi-bicycle {
    color: #626262;
}
body.dark .search_Navigations_wrapper .dropdown .dropdown-item:active, 
body.dark .search_Navigations_wrapper .dropdown .dropdown-item:hover, 
body.dark .search_Navigations_wrapper .dropdown .dropdown-item:focus{
    background-color: #494949 !important;
}
body.dark .btn_close {
    color: #fff;
}
body.dark .search_Navigations_wrapper .btn input,
body.dark .search_Navigations_wrapper .btn input::placeholder {
    background-color: #333;
    border-color: #333;
    color: #fff !important;
}
body.dark .search_Navigations_wrapper .dropdown .bi-search {
    color: #fff !important;
}
body.dark .search_Navigations_wrapper .btn input{
    background-image: url("../img/menu-dots-white.png");
}
body.dark .paginationCustom li:last-child button:before {
    color: transparent;
}
#main_body.dark .paginationCustom li:first-child button, 
#main_body.dark .paginationCustom li:last-child button, 
#main_body.dark .paginationCustom li:nth-child(2) button, 
#main_body.dark .paginationCustom li:nth-last-child(2) button {
    color: transparent !important;
}
#main_body.dark .paginationCustom li:first-child button::before,
#main_body.dark .paginationCustom li:last-child button::before {
    color: #fff !important;
}
#main_body.dark .search_Navigations_wrapper .dropdown .dropdown-item strong,
#main_body.dark .downloadaction {
    color: #fff !important;
}
#main_body.dark #Order-NotBilled-Row td, 
#main_body.dark #Order-NotBilled-Row td:hover,
#main_body.dark #Order-NoActive-Row td, 
#main_body.dark #Order-NoActive-Row td:hover {
    color: #333 !important;
}
#main_body.dark .dark_light_mode_btn {
    background-color: #000;
}
#main_body.dark .dark_light_mode_btn .bi-brightness-high {
    color: #fff !important;
}
#main_body.dark .notinternetFoundPage {
    background: rgb(0 0 0 / 50%);
}
#main_body.dark .notinternetFoundPage .connectwrapp {
    background-color: #333;
    color: #fff;
    border-color: #333;
}
body.dark .ticktes_history_details_modal ul li, 
body.dark .ticktes_history_details_modal ul li::before {
    color: #fff;
}
body.dark .ticktes_history_details_modal ul li::after {
    background-color: #fff;
    color: #fff;
}
#main_body.dark .warehousePriorityCl {
    background-color: #10b068 !important;
}
#main_body.dark .warehousePrioritydanger {
    background-color: #ff8282 !important;
}
#main_body.dark .commonModalStockInwardEmails .images_labels_wrapper li {
    background-color: #000 !important;
}
body.dark .commonModalStockInwardEmails .form-control {
    background-color: #1b1b1b;
    border: 1px solid hsla(0, 0%, 100%, .1) !important;
    color: #fff;
}
body.dark .commonModalStockInwardEmails .form-control::placeholder {
    color: #ccc !important;
}
#main_body.dark .labelAddOnFields small {
    background-color: #1b1b1b !important;
}
body.dark .FranchiseOrdersTablesDetailsTableGeneralERP .tableView tbody tr td {
    background-color: #222428 !important;
}
#main_body.dark .view_more_btn {
    border: 1px solid hsla(0, 0%, 100%, .4) !important;
    color: #fff !important;
}